
import { takeLatest, put, call, select } from "redux-saga/effects";
import { change, initialize } from "redux-form";
import { firebaseAuthentication } from "../../controller/firebase";
import { saveInSession } from "../../controller/session";

import { cargandoModal, construirBuscarOportunidad, dataGraph1, dataGraph2, dataGraph3, formValid, hiddenDisable, loadCatalogSuccess, loginError, loginSuccess, saveBase64Pdf, saveContextAuth0, saveItemsPdf } from "./actions";
import { ACTION_FILTRAR, ACTUALIZAR_OPORTUNIDAD, BUSCAR_OPORTUNIDAD, CARGAR_EVENT, DATA_DIA, DATA_SERVICES, DATA_TIPO_POS, HEADERS_WS, LOAD_CATALOG, LOGIN_ACTION, LOGOUT_ACTION, REDUX_CHANGE, VALIDATE_TYPE_USER } from "./constants";
import { getBase64Pdf, getContactEstadicticForm, getDeportesEstadicticForm, getDynamicOptions, getItemsPdf, getLoginFormValues, getOportunidadForm, getViviendaEstadicticForm } from "./selector";
import { firebaseDatabase } from "../../controller/firebase";
import { emptyObject, getAsyncCatalog, emptyJson, } from "../../controller/utils";


import axios from "axios";
import MyDocument from '../exportpdf/mydocument';
import { pdf } from '@react-pdf/renderer';
import React, { Component } from "react";

//función que realiza la autenticacion, consulta en firebase correo y contraseña
function* loginAction(action) {
    const items = yield select(getLoginFormValues);

    try {
        if (items && items.email && items.password) {
            const { email, password } = items;
            const userCredential = yield firebaseAuthentication.signInWithEmailAndPassword(email, password);

            if (userCredential) {
                saveInSession('currentUser', userCredential.user);
                yield put(loginSuccess({ message: 'Sesión iniciada satisfactorimente', state: true }));
            } else {
                yield put(loginError('Estimado usuario su correo o contraseña son incorrectos, verifique la información e intentelo nuevamente '));
            }

        } else {
            yield put(loginError('Estimado usuario todos los campos son obligatorios, verifique la información e intentelo nuevamente '));
        }
    } catch (e) {
        console.error("error", e);
        yield put(loginError('Estimado usuario su correo o contraseña son incorrectos, verifique la información e intentelo nuevamente '));
    }
}

//función que cierra sesion
function* logoutAction(action) {

    yield put(loginSuccess({ message: 'Sesión limpia', state: false }));
}

//función que descarga todos los catalogos requeridos para sacar los label,
// de cada campo almacendo por su value
function* buildDynamicOptions(type) {
    //inicio el modal de espera 
    const dynamicOptions = {};
    //inicia la descarga
    const sedes = yield getAsyncCatalog("SEDES");
    const related = yield getAsyncCatalog("SERVICIOS");
    const cargos = yield getAsyncCatalog("CARGOS");
    const motivos = yield getAsyncCatalog("MOTIVOS");
    const departamentos = yield getAsyncCatalog("DEPARTAMENTO");
    const cities = yield getAsyncCatalog("CITIES");
    const entidad = yield getAsyncCatalog('ENTIDADES_CREDITO');
    const requesType = yield getAsyncCatalog("TIPO_SOLICITUD_CONTACTO");
    dynamicOptions.departamento = departamentos;
    dynamicOptions.citys = cities;
    dynamicOptions.sedes = sedes;
    dynamicOptions.related = related;
    dynamicOptions.cargos = cargos;
    dynamicOptions.motivos = motivos;
    dynamicOptions.entidad = entidad;
    dynamicOptions.requesType = requesType;

    yield put(loadCatalogSuccess(dynamicOptions));
    //cierra modal de espera
}
//function que retorna los label requeridos para la tabla de contacto
function* crearValueLabel(action) {
    const { catalog, value } = action;
    var res = {
        municipio: '',
        servicio: '',
        sede: '',
        tipo: '',
        cargo: '',
        size: '',
        cityEmpresa: '',
        depaEmpresa: '',
    }
    //recorre cada catalogo para encontrar su label
    catalog.citys.forEach(element => {
        if (element.value === value.city) {
            res.municipio = element.label;
            return;
        }
    });
    catalog.related.forEach(element => {
        if (element.value === value.related) {
            res.servicio = element.label;
            return;
        }
    });
    catalog.sedes.forEach(element => {
        if (element.value === value.site) {
            res.sede = element.label;
            return;
        }
    });
    catalog.requesType.forEach(element => {
        if (element.value === value.tipo) {
            res.tipo = element.label;
            return;
        }
    });
    //solo si es de tipo empresa recorre catalogos propios de ese tipo
    if (value.tipoF === "company") {
        catalog.citys.forEach(element => {
            if (element.value === value.cityEmpresa) {
                res.cityEmpresa = element.label;
                return;
            }
        });
        catalog.departamento.forEach(element => {
            if (element.value === value.depaEmpresa) {
                res.depaEmpresa = element.label;
                return;
            }
        });
        catalog.cargo.forEach(element => {
            if (element.value === value.cargo) {
                res.cargo = element.label;
                return;
            }
        });
        const sizeE = [{
            "label": "Micro (Hasta 10 trabajadores)",
            "value": "1"
        }, {
            "label": "Pequeña (Entre 11 y 50 trabajadores)",
            "value": "2"
        }, {
            "label": "Mediana (Entre  51-100 trabajadores)",
            "value": "3"
        }, {
            "label": "Grande (Más de 100 trabajadores)",
            "value": "4"
        }]
        sizeE.forEach(element => {
            if (element.value === value.size) {
                res.size = element.label;
                return;
            }
        });
    }
    return res;
}

//funcion que valida si un campo es una cadena vacia, falso si es vacia
function emptyBool(value) {
    return value.length > 0 && value !== undefined ? true : false;
}
//function que retorna los label requeridos para la tabla de vivienda
function* crearValueLabelViv(action) {
    const { catalog, value } = action;
    var res = {
        modalidadViv: '',
        tipoAfiliado: '',
        sexo: '',
        estadoCivil: '',
        ocupacion: '',
        condicionJefe: '',
        tipoPostu: '',
        projecttype: '',
        propiedadlote: '',
        fuentesName: '',
        fuentesEntidad: '',
    }

    //como no es garantia que todos los capos sean obligatorios 
    //verifica si es una cadena con información antes de recorrer el catalogo
    if (emptyBool(value.fuentesName)) {
        catalog.fuentes.forEach(element => {
            if (element.value === value.fuentesName) {
                res.fuentesName = element.label;
                return;
            }
        });
    }
    if (emptyBool(value.modalidadViv)) {
        catalog.homeModeType.forEach(element => {
            if (element.value === value.modalidadViv) {
                res.modalidadViv = element.label;
                return;
            }
        });
    }
    if (emptyBool(value.tipoAfiliado)) {
        catalog.postulanteType.forEach(element => {
            if (element.value === value.tipoAfiliado) {
                res.tipoAfiliado = element.label;
                return;
            }
        });
    }
    if (emptyBool(value.propiedadlote)) {
        catalog.propiedadlote.forEach(element => {
            if (element.value === value.propiedadlote) {
                res.propiedadlote = element.label;
                return;
            }
        });
    }
    if (emptyBool(value.sexo)) {
        catalog.sexo.forEach(element => {
            if (element.value === value.sexo) {
                res.sexo = element.label;
                return;
            }
        });
    }
    if (emptyBool(value.estadoCivil)) {
        catalog.maritalstatus.forEach(element => {
            if (element.value === value.estadoCivil) {
                res.estadoCivil = element.label;
                return;
            }
        });
    }
    if (emptyBool(value.ocupacion)) {
        catalog.job.forEach(element => {
            if (element.value === value.ocupacion) {
                res.ocupacion = element.label;
                return;
            }
        });
    }
    if (emptyBool(value.condicionJefe)) {
        catalog.special.forEach(element => {
            if (element.value === value.condicionJefe) {
                res.condicionJefe = element.label;
                return;
            }
        });
    }
    if (emptyBool(value.tipoPostu)) {
        catalog.tipostulante.forEach(element => {
            if (element.value === value.tipoPostu) {
                res.tipoPostu = element.label;
                return;
            }
        });
    }
    if (emptyBool(value.projecttype)) {
        catalog.projecttype.forEach(element => {
            if (element.value === value.projecttype) {
                res.projecttype = element.label;
                return;
            }
        });
    }
    if (emptyBool(value.fuentesEntidad)) {
        catalog.entityFuente.forEach(element => {
            if (element.value === value.fuentesEntidad) {
                res.fuentesEntidad = element.label;
                return;
            }
        });
    }

    return res;
}

//funcion que me retorna el numero de dias que hay entre una fecha y otra
function* contarDias(action) {
    const { fecha2 } = action;
    var fecha1 = action.fecha1;
    var con = 0;
    var ban = false;
    while (!ban) {
        con += 1;
        if (fecha1.getDate() === fecha2.getDate() && fecha1.getFullYear() === fecha2.getFullYear() && fecha1.getMonth() === fecha2.getMonth()) {
            ban = true;
        } else {

            fecha1.setDate(fecha1.getDate() + 1);
        }
    }
    return con;
}

//cuando se selecciona un rango personalizado, y se pincha en filtrar
function* actionFiltrar(action) {
    const { value: { field: { name } } } = action;

    if (name === "btnFiltrarCont") {
        const data = yield select(getContactEstadicticForm);
        if (data.personalizadoFiltro && data.personalizadoFiltro.fechaStart && data.personalizadoFiltro.fechaEnd) {
            const fechaStart = data.personalizadoFiltro.fechaStart;
            const fechaEnd = data.personalizadoFiltro.fechaEnd;

            const tem1 = new Date(fechaStart);
            const tem2 = new Date(fechaEnd);

            if (tem2 >= tem1) {
                yield put(cargandoModal(true))
                tem1.setDate(tem1.getDate() + 1)
                tem2.setDate(tem2.getDate() + 1)

                const valor = yield contarDias({ fecha1: tem1, fecha2: tem2 })
                const fecha1 = new Date(fechaStart);
                fecha1.setDate(fecha1.getDate() + 1);
                const f1 = yield crearHoraCero(fecha1);
                const f2 = yield crearHoraFin(new Date(tem2));
                const rango = { fechaIni: f1, fechaFin: f2 }

                if (valor === 1) {
                    yield consultarEnFirebase({ rango: rango, selection: "1", coleccion: "contactenos" })
                } else {
                    yield consultarEnFirebase({ rango: rango, selection: "6", coleccion: "contactenos" })
                }

            } else {
                yield put(formValid("El rango seleccionado es incorrecto, intentelo nuevamente"))
            }
        } else {
            yield put(formValid("Los campos de fecha no pueden estar vacios al seleccionar la opción 'personalizado'"))
        }
    }
    if (name === "btnFiltrarViv") {

        const data = yield select(getViviendaEstadicticForm);
        if (data.personalizadoVivienda && data.personalizadoVivienda.fechaStart && data.personalizadoVivienda.fechaEnd) {
            const fechaStart = data.personalizadoVivienda.fechaStart;
            const fechaEnd = data.personalizadoVivienda.fechaEnd;

            const tem1 = new Date(fechaStart);
            const tem2 = new Date(fechaEnd);

            if (tem2 >= tem1) {

                tem1.setDate(tem1.getDate() + 1)
                tem2.setDate(tem2.getDate() + 1)

                const valor = yield contarDias({ fecha1: tem1, fecha2: tem2 })
                const fecha1 = new Date(fechaStart);
                fecha1.setDate(fecha1.getDate() + 1);
                const f1 = yield crearHoraCero(fecha1);
                const f2 = yield crearHoraFin(new Date(tem2));
                const rango = { fechaIni: f1, fechaFin: f2 }
                yield put(cargandoModal(true))
                if (valor === 1) {
                    yield consultarEnFirebase({ rango: rango, selection: "1", coleccion: "solicitudesVivienda" })
                } else {
                    yield consultarEnFirebase({ rango: rango, selection: "6", coleccion: "solicitudesVivienda" })
                }

            } else {
                yield put(formValid("El rango seleccionado es incorrecto, intentelo nuevamente"))
            }
        } else {
            yield put(formValid("Los campos de fecha no pueden estar vacios al seleccionar la opción 'personalizado'"))
        }
    }

    if (name === "btnFiltrarDeport") {

        const data = yield select(getDeportesEstadicticForm);
        if (data.personalizadoDeportes && data.personalizadoDeportes.fechaStart && data.personalizadoDeportes.fechaEnd) {
            const fechaStart = data.personalizadoDeportes.fechaStart;
            const fechaEnd = data.personalizadoDeportes.fechaEnd;

            const tem1 = new Date(fechaStart);
            const tem2 = new Date(fechaEnd);

            if (tem2 >= tem1) {

                tem1.setDate(tem1.getDate() + 1)
                tem2.setDate(tem2.getDate() + 1)

                const valor = yield contarDias({ fecha1: tem1, fecha2: tem2 })
                const fecha1 = new Date(fechaStart);
                fecha1.setDate(fecha1.getDate() + 1);
                const f1 = yield crearHoraCero(fecha1);
                const f2 = yield crearHoraFin(new Date(tem2));
                const rango = { fechaIni: f1, fechaFin: f2 }
                yield put(cargandoModal(true))
                if (valor === 1) {
                    yield consultarEnFirebase({ rango: rango, selection: "1", coleccion: "deportes" })
                } else {
                    yield consultarEnFirebase({ rango: rango, selection: "6", coleccion: "deportes" })
                }

            } else {
                yield put(formValid("El rango seleccionado es incorrecto, intentelo nuevamente"))
            }
        } else {
            yield put(formValid("Los campos de fecha no pueden estar vacios al seleccionar la opción 'personalizado'"))
        }
    }


}

//cuando se selecciona uno de los rangos de la lista
function* consultarSelect(action) {
    const { meta: { form, field }, payload } = action;
    if (payload) {
        if (form === "contactEstadisticForm" && field === "selectRange") {
            if (payload === "8") {
                yield put(hiddenDisable({ state: false, field: "personalizadoFiltro" }))
                //mostrar fechas y boton
            } else {
                yield put(cargandoModal(true))
                yield put(hiddenDisable({ state: true, field: "personalizadoFiltro" }))
                //consultar con payload

                const rango = yield crearFecha(payload);
                yield consultarEnFirebase({ rango, selection: payload, coleccion: "contactenos" })
            }
        }

        if (form === "viviendaEstadisticForm" && field === "selectRange") {
            if (payload === "8") {
                yield put(hiddenDisable({ state: false, field: "personalizadoVivienda" }))
                yield put(cargandoModal(false))
                //mostrar fechas y boton
            } else {
                yield put(cargandoModal(true))
                yield put(hiddenDisable({ state: true, field: "personalizadoVivienda" }))
                //consultar con payload

                const rango = yield crearFecha(payload)
                yield consultarEnFirebase({ rango, selection: payload, coleccion: "solicitudesVivienda" })
            }
        }

        if (form === "deportEstadisticForm" && field === "selectRange") {

            if (payload === "8") {
                yield put(hiddenDisable({ state: false, field: "personalizadoDeportes" }))
                yield put(cargandoModal(false))
                //mostrar fechas y boton
            } else {
                yield put(cargandoModal(true))
                yield put(hiddenDisable({ state: true, field: "personalizadoDeportes" }))
                //consultar con payload

                const rango = yield crearFecha(payload)
                yield consultarEnFirebase({ rango, selection: payload, coleccion: "deportes" })
            }
        }
    }

}

//crea la primera hora del dia 
function* crearHoraCero(action) {
    var temp = action.toString().split(" ")
    var aux = `${temp[0]} ${temp[1]} ${temp[2]} ${temp[3]} 00:01:00 GMT-0500 (hora estándar de Colombia)`
    const fe = new Date(aux)
    return fe;
}

//crea la ultima hora del dia
function* crearHoraFin(action) {
    var temp = action.toString().split(" ")
    var aux = `${temp[0]} ${temp[1]} ${temp[2]} ${temp[3]} 23:59:00 GMT-0500 (hora estándar de Colombia)`
    const fe = new Date(aux)
    return fe;
}
//resta dos fechas
function* restarFechas(action) {
    const { dias } = action;
    var aux = new Date;
    aux.setDate(aux.getDate() - dias);
    return aux;
}

//obtiene el dia lunes de una semana dada por una fecha
function* getLunesDate() {
    var aux = new Date();
    if (aux.getDay() !== 1) {
        //no es un lunes 
        var ban = true;
        aux.setDate(aux.getDate() - 1);
        while (ban) {
            if (aux.getDay() === 1) {
                ban = false;
            } else {
                aux.setDate(aux.getDate() - 1);
            }
        }
    }
    return aux;
}

//crea el rango de fechas a consultar dependiendo lo seleccionado en la lista
function* crearFecha(action) {
    var fechas = {};
    const dateHoy = new Date();
    dateHoy.setDate(dateHoy.getDate() - 1)
    switch (action) {
        case "1":
            const fe = yield crearHoraCero(new Date());
            fechas = { fechaIni: fe, fechaFin: new Date() }
            break;
        case "2":
            const fechaAux2 = yield restarFechas({ dias: 1 });
            const fe2 = yield crearHoraCero(fechaAux2);
            const fin = yield crearHoraFin(fe2);
            fechas = { fechaIni: fe2, fechaFin: fin }
            break;
        case "3":
            const fechaAux3 = yield restarFechas({ dias: 7 });
            const fe3 = yield crearHoraCero(fechaAux3);
            const fin3 = yield crearHoraFin(dateHoy);
            fechas = { fechaIni: fe3, fechaFin: fin3 }
            break;
        case "4":
            const fechaAux4 = yield restarFechas({ dias: 15 });
            const fe4 = yield crearHoraCero(fechaAux4);
            const fin4 = yield crearHoraFin(dateHoy);
            fechas = { fechaIni: fe4, fechaFin: fin4 }
            break;
        case "5":
            const fechaAux5 = yield getLunesDate();
            const fe5 = yield crearHoraCero(fechaAux5);
            const fin5 = yield crearHoraFin(new Date());
            fechas = { fechaIni: fe5, fechaFin: fin5 }
            break;
        case "6":
            const fechaAux6 = new Date();
            fechaAux6.setDate(1)
            const fe6 = yield crearHoraCero(fechaAux6);
            const fin6 = yield crearHoraFin(dateHoy);
            fechas = { fechaIni: fe6, fechaFin: fin6 }
            break;
        case "7":
            var aux = new Date();
            aux.setDate(1);
            aux.setDate(aux.getDate() - 1);
            const date1 = new Date();
            date1.setDate(1);
            date1.setDate(1);
            date1.setDate(date1.getDate() - 1);
            date1.setDate(1);
            const fe7 = yield crearHoraCero(date1);
            fechas = { fechaIni: fe7, fechaFin: aux }
            break;
        default:
            break;

    }

    return fechas;
    //data una seleccion crear un rango de fecha de consulta
}

//trae todos los datos de firebase dependiendo el tab desde el que se realiza la peticion
function* consultarEnFirebase(action) {
    const { rango, selection, coleccion } = action;

    if (coleccion === "contactenos") {
        var currentFormValues = false;
        const reference = yield firebaseDatabase.collection(coleccion).
            where('dateTime', '>', rango.fechaIni).
            where('dateTime', "<", rango.fechaFin).get();
        var respuesta = []
        yield reference.docs.map((doc, i) => {
            currentFormValues = doc.data();
            respuesta.push(currentFormValues)
        });
        var data = [];
        if (respuesta.length > 0) {
            respuesta.forEach(element => {
                data.push({ date: element.dateTime, datos: element.datos, tipo: element.tipo, comfenalcoTicket: emptyObject(element.comfenalcoTicket), ErrorSistemaEnvioPeticion: emptyObject(element.ErrorSistemaEnvioPeticion) });
            });

        }
        yield llenarGrafico({ data, selection, rango, coleccion });
    }

    if (coleccion === "solicitudesVivienda") {
        var currentFormValues = false;
        const reference = yield firebaseDatabase.collection(coleccion).
            where('date', '>', rango.fechaIni).
            where('date', "<", rango.fechaFin).get();
        var respuesta = []
        yield reference.docs.map((doc, i) => {
            currentFormValues = doc.data();
            currentFormValues.id = doc.f_.path.segments[6];
            respuesta.push(currentFormValues)
        });
        var data = [];
        if (respuesta.length > 0) {
            respuesta.forEach(element => {
                data.push(element);
            });

        }
        yield llenarGrafico({ data, selection, rango, coleccion });
    }

    if (coleccion === "deportes") {
        var currentFormValues = false;
        const reference = yield firebaseDatabase.collection(coleccion).
            where('dateTime', '>', rango.fechaIni).
            where('dateTime', "<", rango.fechaFin).get();
        var data = []
        yield reference.docs.map((doc, i) => {
            currentFormValues = doc.data();
            currentFormValues.id = doc.f_.path.segments[6];
            data.push(currentFormValues);
        });

        yield llenarGrafico({ data, selection, rango, coleccion });
    }


}

//si el rango es un solo dia, crea el numero de horas del dia para los graficos
function crearHorasRango(action) {
    const { coleccion } = action;
    const tipo = [];

    var cont = 0;
    while (cont < 25) {
        if (coleccion === "contactenos") {
            tipo.push({ hour: `${cont.toString()}:00`, Z01: 0, Z02: 0, Z04: 0, Z05: 0, Z11: 0, peopleT: 0, peopleF: 0, companyT: 0, companyF: 0 });
        }

        if (coleccion === "solicitudesVivienda") {
            tipo.push({ hour: `${cont.toString()}:00`, urbanoB: 0, urbanoF: 0, ruralB: 0, ruralF: 0, VIS: 0, VIPA: 0, borrador: 0, finalizado: 0, VIP: 0, DEP: 0, INDEP: 0, MADRE: 0, PEN: 0, HM1: 0, HM2: 0, HM3: 0, HM4: 0 });
        }

        if (coleccion === "deportes") {
            tipo.push({ hour: `${cont.toString()}:00`, G1: 0, G2: 0, });
        }

        cont += 1;
    }

    return tipo;

}
//si el rango es mayor a un dia, crea el numero de fechas para los graficos
function crearDiasRango(action) {
    const { rango, coleccion } = action;
    const tipo = [];
    var aux = rango.fechaIni;
    var ban = true;
    var con = 0;
    while (ban) {

        const temp = `${aux.getDate().toString()}/${(aux.getMonth() + 1).toString()}/${aux.getFullYear().toString()}`;
        if (coleccion === "contactenos") {
            tipo.push({ hour: temp, Z01: 0, Z02: 0, Z04: 0, Z05: 0, Z11: 0, peopleT: 0, peopleF: 0, companyT: 0, companyF: 0 });
        }
        if (coleccion === "solicitudesVivienda") {
            tipo.push({ hour: temp, urbanoB: 0, urbanoF: 0, ruralB: 0, ruralF: 0, VIS: 0, VIPA: 0, borrador: 0, finalizado: 0, VIP: 0, DEP: 0, INDEP: 0, MADRE: 0, PEN: 0, HM1: 0, HM2: 0, HM3: 0, HM4: 0 });
        }
        if (coleccion === "deportes") {
            tipo.push({ hour: temp, G1: 0, G2: 0, });
        }
        if (aux.getFullYear() === rango.fechaFin.getFullYear() && aux.getMonth() === rango.fechaFin.getMonth() && aux.getDate() === rango.fechaFin.getDate()) {
            ban = false;
        }
        aux.setDate(aux.getDate() + 1);


    }

    return tipo;

}
//organiza los datos de vivienda para los graficos lineales y los envia al componente
function* getDataViv(action) {
    const { data, table, pie } = action;
    var datas = {
        hour: [],
        urbanoB: [],
        urbanoF: [],
        ruralB: [],
        ruralF: [],
        VIS: [],
        VIPA: [],
        borrador: [],
        finalizado: [],
        VIP: [],
        DEP: [],
        INDEP: [],
        MADRE: [],
        PEN: [],
        HM1: [],
        HM2: [],
        HM3: [],
        HM4: [],
    }

    if (data.length > 0) {
        data.forEach(element => {
            datas.hour.push(element.hour);
            datas.urbanoB.push(element.urbanoB);
            datas.urbanoF.push(element.urbanoF);
            datas.ruralB.push(element.ruralB);
            datas.ruralF.push(element.ruralF);
            datas.VIS.push(element.VIS);
            datas.VIPA.push(element.VIPA);
            datas.borrador.push(element.borrador);
            datas.finalizado.push(element.finalizado);
            datas.VIP.push(element.VIP);
            datas.DEP.push(element.DEP);
            datas.INDEP.push(element.INDEP);
            datas.MADRE.push(element.MADRE);
            datas.PEN.push(element.PEN);
            datas.HM1.push(element.HM1);
            datas.HM2.push(element.HM2);
            datas.HM3.push(element.HM3);
            datas.HM4.push(element.HM4);
        });
    }

    const graph1 = {
        labels: datas.hour,
        datasets: [
            {
                label: 'Urbano en borrador',
                data: datas.urbanoB,
                fill: false,
                tension: .4,
                borderColor: '#42A5F5'
            },
            {
                label: 'Urbano finalizado',
                data: datas.urbanoF,
                fill: false,
                //borderDash: [5, 5],
                tension: .4,
                borderColor: '#66BB6A'
            },
            {
                label: 'Rural en borrador',
                data: datas.ruralB,
                fill: true,
                borderColor: '#FFA726',
                tension: .4,
                // backgroundColor: 'rgba(255,167,38,0.2)'
            },
            {
                label: 'Rural finalizado',
                data: datas.ruralF,
                fill: true,
                borderColor: '#CF12B5',
                tension: .4,
                //backgroundColor: 'rgba(255,120,38,0.2)'
            },
        ]
    };
    const graph2 = {
        labels: datas.hour,
        datasets: [
            {
                label: 'Adquisición de vivienda nueva',
                data: datas.HM1,
                fill: false,
                tension: .4,
                borderColor: '#42A5F5'
            },
            {
                label: 'Construcción en sitio propio',
                data: datas.HM2,
                fill: false,
                //borderDash: [5, 5],
                tension: .4,
                borderColor: '#66BB6A'
            },
            {
                label: 'Mejoramiento de vivienda',
                data: datas.HM3,
                fill: true,
                borderColor: '#FFA726',
                tension: .4,
                // backgroundColor: 'rgba(255,167,38,0.2)'
            },
            {
                label: 'Vivienda usada',
                data: datas.HM4,
                fill: true,
                borderColor: '#CF12B5',
                tension: .4,
                //backgroundColor: 'rgba(255,120,38,0.2)'
            },

        ]
    };
    const graph3 = {
        labels: datas.hour,
        datasets: [
            {
                label: 'Dependiente',
                data: datas.DEP,
                fill: false,
                tension: .4,
                borderColor: '#42A5F5'
            },
            {
                label: 'Pensionado',
                data: datas.PEN,
                fill: false,
                //borderDash: [5, 5],
                tension: .4,
                borderColor: '#66BB6A'
            },
            {
                label: 'Madre comunitaria',
                data: datas.MADRE,
                fill: true,
                borderColor: '#FFA726',
                tension: .4,
                // backgroundColor: 'rgba(255,167,38,0.2)'
            },
            {
                label: 'Independiente',
                data: datas.INDEP,
                fill: true,
                borderColor: '#CF12B5',
                tension: .4,
                //backgroundColor: 'rgba(255,120,38,0.2)'
            },

        ]
    };
    const graph4 = {
        labels: datas.hour,
        datasets: [
            {
                label: 'Vivienda de interes social',
                data: datas.VIS,
                fill: false,
                tension: .4,
                borderColor: '#42A5F5'
            },
            {
                label: 'Vivienda de interes prioritario',
                data: datas.VIP,
                fill: false,
                //borderDash: [5, 5],
                tension: .4,
                borderColor: '#66BB6A'
            },
            {
                label: 'Vivienda de interes prioritario para ahorro',
                data: datas.VIPA,
                fill: true,
                borderColor: '#FFA726',
                tension: .4,
                // backgroundColor: 'rgba(255,167,38,0.2)'
            },

        ]
    };
    const graph5 = {
        labels: datas.hour,
        datasets: [
            {
                label: 'Borrador',
                data: datas.borrador,
                fill: false,
                tension: .4,
                borderColor: '#42A5F5'
            },
            {
                label: 'Finalizado',
                data: datas.finalizado,
                fill: false,
                //borderDash: [5, 5],
                tension: .4,
                borderColor: '#66BB6A'
            },

        ]
    };

    yield put(dataGraph2({ graph1, graph2, graph3, graph4, graph5, table, pie }));
}
//organiza los datos de contacto para los graficos lineales y los envia al componente
function* getDataContact(action) {
    const { data, table, pie } = action;
    var datas = {
        hour: [],
        Z01: [],
        Z02: [],
        Z04: [],
        Z05: [],
        Z11: [],
        personaT: [],
        empresaT: [],
        personaF: [],
        empresaF: [],
    }
    if (data.length > 0) {
        data.forEach(element => {
            datas.hour.push(element.hour);
            datas.Z01.push(element.Z01);
            datas.Z02.push(element.Z02);
            datas.Z04.push(element.Z04);
            datas.Z05.push(element.Z05);
            datas.Z11.push(element.Z11);
            datas.personaT.push(element.peopleT);
            datas.empresaT.push(element.companyT);
            datas.personaF.push(element.peopleF);
            datas.empresaF.push(element.companyF);

        });
    }

    const graph1 = {
        labels: datas.hour,
        datasets: [
            {
                label: 'Queja',
                data: datas.Z01,
                fill: false,
                tension: .4,
                borderColor: '#42A5F5'
            },
            {
                label: 'Reclamo',
                data: datas.Z02,
                fill: false,
                //borderDash: [5, 5],
                tension: .4,
                borderColor: '#66BB6A'
            },
            {
                label: 'Sugerencia',
                data: datas.Z04,
                fill: true,
                borderColor: '#FFA726',
                tension: .4,
                // backgroundColor: 'rgba(255,167,38,0.2)'
            },
            {
                label: 'Comentario positivo',
                data: datas.Z05,
                fill: true,
                borderColor: '#CF12B5',
                tension: .4,
                //backgroundColor: 'rgba(255,120,38,0.2)'
            },
            {
                label: 'Solicitud',
                data: datas.Z11,
                fill: true,
                borderColor: '#FA0808',
                tension: .4,
                //backgroundColor: 'rgba(130,167,38,0.2)'
            }
        ]
    };

    const graph2 = {
        labels: datas.hour,
        datasets: [
            {
                label: 'Personas con ticketID',
                data: datas.personaT,
                fill: true,
                borderColor: '#11DA6C',
                tension: .4,
                backgroundColor: '#C4F6E9'
            },
            {
                label: 'Personas con Error Petición',
                data: datas.personaF,
                fill: true,
                borderColor: '#116FDA',
                tension: .4,
                backgroundColor: '#C4CBF6'
            },
            {
                label: 'Empresas con ticketID ',
                data: datas.empresaT,
                fill: true,
                borderColor: '#A0DA11',
                tension: .4,
                backgroundColor: '#D5FEA8'
            }, {
                label: 'Empresas con Error Petición ',
                data: datas.empresaF,
                fill: true,
                borderColor: '#DA8511',
                tension: .4,
                backgroundColor: '#FED8A8'
            }
        ]
    };

    yield put(dataGraph1({ graph1, graph2, table, pie }));
}

function* getDataDeporte(action) {
    const { data, table, pie } = action;
    var datas = {
        hour: [],
        G1: [],
        G2: [],
    }
    if (data.length > 0) {
        data.forEach(element => {
            datas.hour.push(element.hour);
            datas.G1.push(element.G1);
            datas.G2.push(element.G2);
        });
    }

    const graph1 = {
        labels: datas.hour,
        datasets: [
            {
                label: 'Niños de 5 - 11',
                data: datas.G1,
                fill: false,
                tension: .4,
                borderColor: '#42A5F5'
            },
            {
                label: 'Niños de 12 - 15',
                data: datas.G2,
                fill: false,
                //borderDash: [5, 5],
                tension: .4,
                borderColor: '#66BB6A'
            },
        ]
    };


    yield put(dataGraph3({ graph1, table, pie }));
}
//realiza el conteo dependiendo de la especificacion para enviarlo a los graficos
function* llenarGrafico(action) {
    var tipo1 = []
    const { data, selection, rango, coleccion } = action;
    if (coleccion === "contactenos") {
        if (selection === "1" || selection === "2") {
            tipo1 = yield crearHorasRango({ coleccion });
            data.forEach(element => {
                const temp = element.date.toDate();
                const aux = temp.getHours().toString();

                tipo1.forEach(tipos => {
                    const aux2 = tipos.hour.split(":");
                    if (aux2[0] === aux) {
                        if (element.datos?.informacionsolicitud?.requesType) {
                            tipos[element.datos?.informacionsolicitud?.requesType] += 1;
                        }
                        if (element.tipo === "people" && element.comfenalcoTicket) {
                            tipos.peopleT += 1;
                        }
                        if (element.tipo === "people" && element.ErrorSistemaEnvioPeticion) {
                            tipos.peopleF += 1;
                        }

                        if (element.tipo === "company" && element.comfenalcoTicket) {
                            tipos.companyT += 1;
                        }
                        if (element.tipo === "company" && element.ErrorSistemaEnvioPeticion) {
                            tipos.companyF += 1;
                        }
                    }

                });
            });

        } else {

            tipo1 = yield crearDiasRango({ rango, coleccion });

            data.forEach(element => {
                const temp = element.date.toDate();
                const aux = temp.getFullYear();
                const aux2 = temp.getMonth();
                const aux3 = temp.getDate();

                tipo1.forEach(tipos => {
                    var spl = tipos.hour.split("/");
                    if (parseInt(spl[2]) === aux && (parseInt(spl[1]) - 1) === aux2 && parseInt(spl[0]) === aux3) {
                        if (element.datos?.informacionsolicitud?.requesType) {
                            tipos[element.datos?.informacionsolicitud?.requesType] += 1;
                        }
                        if (element.tipo === "people" && element.comfenalcoTicket) {
                            tipos.peopleT += 1;
                        }
                        if (element.tipo === "people" && element.ErrorSistemaEnvioPeticion) {
                            tipos.peopleF += 1;
                        }

                        if (element.tipo === "company" && element.comfenalcoTicket) {
                            tipos.companyT += 1;
                        }
                        if (element.tipo === "company" && element.ErrorSistemaEnvioPeticion) {
                            tipos.companyF += 1;
                        }
                    }

                });
            });
        }
        const table = yield crearDataTable({ data, coleccion });
        const pie = yield crearPieChart({ datos: data, coleccion });
        yield put(cargandoModal(false))
        yield getDataContact({ data: tipo1, table, pie });
    }
    if (coleccion === "solicitudesVivienda") {
        if (selection === "1" || selection === "2") {
            tipo1 = yield crearHorasRango({ coleccion });

            data.forEach(element => {
                const temp = element.date.toDate();
                const aux = temp.getHours().toString();

                tipo1.forEach(tipos => {
                    const aux2 = tipos.hour.split(":");
                    if (aux2[0] === aux) {
                        if (element.step3?.informacionbasica?.homeType === "rural") {
                            if (element.state === "finalizado") {
                                tipos.ruralF += 1;
                            }
                            if (element.state === "borrador") {
                                tipos.ruralB += 1;
                            }
                        }

                        if (element.step3?.informacionbasica?.homeType === "urbano") {
                            if (element.state === "finalizado") {
                                tipos.urbanoF += 1;
                            }
                            if (element.state === "borrador") {
                                tipos.urbanoB += 1;
                            }
                        }

                        if (element.step3?.informacionbasica?.planHomeVivienda) {
                            tipos[element.step3?.informacionbasica?.planHomeVivienda] += 1;
                        }

                        if (element.state) {
                            tipos[element.state] += 1;
                        }

                        if (element.step3?.informacionbasica?.homeModeType === "1") {
                            tipos.HM1 += 1;
                        }
                        if (element.step3?.informacionbasica?.homeModeType === "2") {
                            tipos.HM2 += 1;
                        }
                        if (element.step3?.informacionbasica?.homeModeType === "3") {
                            tipos.HM3 += 1;
                        }
                        if (element.step3?.informacionbasica?.homeModeType === "4") {
                            tipos.HM4 += 1;
                        }
                        if (element.step3?.informacionbasica?.postulanteType === "1") {
                            tipos.DEP += 1;
                        }
                        if (element.step3?.informacionbasica?.postulanteType === "2") {
                            tipos.PEn += 1;
                        }
                        if (element.step3?.informacionbasica?.postulanteType === "3") {
                            tipos.MADRE += 1;
                        }
                        if (element.step3?.informacionbasica?.postulanteType === "4") {
                            tipos.INDEP += 1;
                        }

                    }

                });
            });

        } else {

            tipo1 = yield crearDiasRango({ rango, coleccion });
            data.forEach(element => {
                const temp = element.date.toDate();
                const aux = temp.getFullYear();
                const aux2 = temp.getMonth();
                const aux3 = temp.getDate();

                tipo1.forEach(tipos => {
                    var spl = tipos.hour.split("/");
                    if (parseInt(spl[2]) === aux && (parseInt(spl[1]) - 1) === aux2 && parseInt(spl[0]) === aux3) {
                        if (element.step3?.informacionbasica?.homeType === "rural") {
                            if (element.state === "finalizado") {
                                tipos.ruralF += 1;
                            }
                            if (element.state === "borrador") {
                                tipos.ruralB += 1;
                            }
                        }

                        if (element.step3?.informacionbasica?.homeType === "urbano") {
                            if (element.state === "finalizado") {
                                tipos.urbanoF += 1;
                            }
                            if (element.state === "borrador") {
                                tipos.urbanoB += 1;
                            }
                        }

                        if (element.step3?.informacionbasica?.planHomeVivienda) {
                            tipos[element.step3?.informacionbasica?.planHomeVivienda] += 1;
                        }

                        if (element.state) {
                            tipos[element.state] += 1;
                        }

                        if (element.step3?.informacionbasica?.homeModeType === "1") {
                            tipos.HM1 += 1;
                        }
                        if (element.step3?.informacionbasica?.homeModeType === "2") {
                            tipos.HM2 += 1;
                        }
                        if (element.step3?.informacionbasica?.homeModeType === "3") {
                            tipos.HM3 += 1;
                        }
                        if (element.step3?.informacionbasica?.homeModeType === "4") {
                            tipos.HM4 += 1;
                        }
                        if (element.step3?.informacionbasica?.postulanteType === "1") {
                            tipos.DEP += 1;
                        }
                        if (element.step3?.informacionbasica?.postulanteType === "2") {
                            tipos.PEn += 1;
                        }
                        if (element.step3?.informacionbasica?.postulanteType === "3") {
                            tipos.MADRE += 1;
                        }
                        if (element.step3?.informacionbasica?.postulanteType === "4") {
                            tipos.INDEP += 1;
                        }

                    }

                });
            });
        }
        const table = yield crearDataTable({ data, coleccion });
        const pie = yield crearPieChart({ datos: data, coleccion });
        yield put(cargandoModal(false))
        yield getDataViv({ data: tipo1, table, pie });
    }
    if (coleccion === "deportes") {

        if (selection === "1" || selection === "2") {
            tipo1 = yield crearHorasRango({ coleccion });
            data.forEach(element => {
                const temp = element.date.toDate();
                const aux = temp.getHours().toString();

                tipo1.forEach(tipos => {
                    const aux2 = tipos.hour.split(":");
                    if (aux2[0] === aux) {
                        if (element.datos?.companydataform?.grupo === "G1") {
                            tipos["G1"] += 1;
                        }
                        if (element.datos?.companydataform?.grupo === "G2") {
                            tipos["G2"] += 1;
                        }
                    }

                });
            });

        } else {

            tipo1 = yield crearDiasRango({ rango, coleccion });

            data.forEach(element => {
                const temp = element.dateTime.toDate();
                const aux = temp.getFullYear();
                const aux2 = temp.getMonth();
                const aux3 = temp.getDate();

                tipo1.forEach(tipos => {
                    var spl = tipos.hour.split("/");
                    if (parseInt(spl[2]) === aux && (parseInt(spl[1]) - 1) === aux2 && parseInt(spl[0]) === aux3) {
                        if (element.datos?.companydataform?.grupo === "G1") {
                            tipos["G1"] += 1;
                        }
                        if (element.datos?.companydataform?.grupo === "G2") {
                            tipos["G2"] += 1;
                        }

                    }

                });
            });
        }
        const table = yield crearDataTable({ data, coleccion });
        const pie = yield crearPieChart({ datos: data, coleccion });
        yield put(cargandoModal(false))
        yield getDataDeporte({ data: tipo1, table, pie });

    }


}

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

//realiza el conteo para llenar el pieChart
function* crearPieChart(action) {
    const { datos, coleccion } = action;
    const label = [];
    const data = [];
    const color = [];
    if (coleccion === "contactenos") {
        if (datos.length > 0) {
            const pieJson = DATA_SERVICES;
            pieJson.forEach(pie => {
                var cont = 0;
                datos.forEach(element => {
                    if (pie.value === element.datos?.informacionsolicitud?.related) {
                        cont += 1;
                    }
                });
                if (cont > 0) {
                    label.push(pie.label);
                    data.push(cont);
                    color.push(pie.color);
                }
            });
        }

    }
    if (coleccion === "solicitudesVivienda") {
        if (datos.length > 0) {
            const pieJson = DATA_TIPO_POS;
            pieJson.forEach(pie => {
                var cont = 0;
                datos.forEach(element => {
                    if (pie.value === element.step1?.informacionbasica?.postulanteType) {
                        cont += 1;
                    }
                });
                if (cont > 0) {
                    label.push(pie.label);
                    data.push(cont);
                    color.push(pie.color);
                }
            });
        }
    }

    if (coleccion === "deportes") {
        if (datos.length > 0) {
            var headPie = [];
            datos.forEach(element => {
                if (element.datos?.companydataform?.centroRecreacional?.length > 0) {
                    headPie.push(element.datos?.companydataform?.centroRecreacional);
                }
            });
            var headPieAx = headPie.filter(onlyUnique);

            if (headPieAx.length > 0) {
                headPieAx.forEach(pie => {
                    var cont = 0;
                    datos.forEach(element => {
                        if (element.datos?.companydataform?.centroRecreacional?.length > 0) {
                            if (pie === element.datos?.companydataform?.centroRecreacional) {
                                cont += 1;
                            }
                        }
                    });
                    if (cont > 0) {
                        label.push(pie);
                        data.push(cont);
                        color.push(setBg());
                    }
                });
            }
        }

    }

    return { label, data, color };

}

const setBg = () => {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
}

function convertirFecha(fecha) {
    if (fecha) {
        const fech = fecha.toDate();
        return `${fech.getDate().toString()}/${(fech.getMonth() + 1).toString()}/${fech.getFullYear().toString()} 
        ${fech.getHours().toString()}:${fech.getMinutes().toString()}:${fech.getSeconds().toString()}`;
    } else {
        return "0-0-0000";
    }
}
//crea los datos que van en la tabla para cada campo, los header son constantes
function* crearDataTable(action) {
    const { data, coleccion } = action;
    const catalog = yield select(getDynamicOptions)
    const datos = [];
    const datos1 = [];
    const datos2 = [];
    if (coleccion === "contactenos") {
        for (var element of data) {
            if (element.tipo === "people") {
                var value = {
                    city: element.datos?.informacionpersonal?.idMuniResidencia,
                    related: element.datos?.informacionsolicitud?.related,
                    site: element.datos?.informacionsolicitud?.site,
                    tipo: element.datos?.informacionsolicitud?.requesType,
                    tipoF: element.tipo,
                }
                const res = yield crearValueLabel({ catalog, value })
                const municipio = res.municipio;
                const servicio = res.servicio;
                const sede = res.sede;
                const tipo = res.tipo;

                datos1.push(
                    {
                        tiket: emptyObject(element.comfenalcoTicket),
                        tiketError: emptyObject(element.ErrorSistemaEnvioPeticion),
                        tipo: element.tipo,
                        fecha: convertirFecha(element.date),
                        name: `${element.datos?.informacionpersonal?.primerNombre} ${element.datos?.informacionpersonal?.segundoNombre}`,
                        apellido: element.datos?.informacionpersonal?.primerApellido,
                        nacimiento: element.datos?.informacionpersonal?.nacimiento,
                        genero: element.datos?.informacionpersonal?.genero,
                        email: element.datos?.informacionpersonal?.email,
                        tipoDoc: element.datos?.informacionpersonal?.abreviatura,
                        doc: element.datos?.informacionpersonal?.identificacion,
                        celular: element.datos?.informacionpersonal?.celular,
                        city: municipio,
                        fijo: emptyObject(element.datos?.informacionpersonal?.phone),
                        observacion: element.datos?.informacionsolicitud?.requestText,
                        servicio: servicio,
                        sede: sede,
                        request: tipo,
                    }
                )
            } else {
                var value = {
                    city: element.datos?.informacionpersonal?.idMuniResidencia,
                    related: element.datos?.companyrequestform?.related,
                    site: element.datos?.companyrequestform?.site,
                    tipo: element.datos?.companyrequestform?.requesType,
                    cargo: element.datos?.informacionpersonal?.cargo,
                    depaEmpresa: element.datos?.companydataform?.companyState,
                    cityEmpresa: element.datos?.companydataform?.companyTown,
                    size: element.datos?.companydataform?.companySize,
                    tipoF: element.tipo,
                }
                const res = yield crearValueLabel({ catalog, value })

                datos2.push(
                    {

                        tipoIdEmpresa: element.datos.companydataform.documentCompanyType,
                        idEmpresa: element.datos.companydataform.companyDocument,
                        razon: element.datos.companydataform.socialName,
                        celEmpresa: element.datos.companydataform.companyMobilePhone,
                        fijoEmpresa: element.datos.companydataform.companyPhone,
                        emailEmpresa: element.datos.companydataform.companyEmail,
                        depaEmpresa: res.depaEmpresa,
                        cityEmpresa: res.cityEmpresa,
                        sizeEmpresa: res.size,
                        cargo: res.cargo,
                        tiket: emptyObject(element.comfenalcoTicket),
                        tiketError: emptyObject(element.ErrorSistemaEnvioPeticion),
                        tipo: element.tipo,
                        fecha: convertirFecha(element.dateTime),
                        name: `${element.datos?.informacionpersonal?.primerNombre} ${element.datos?.informacionpersonal?.segundoNombre}`,
                        apellido: element.datos?.informacionpersonal?.primerApellido,
                        nacimiento: element.datos?.informacionpersonal?.nacimiento,
                        genero: element.datos?.informacionpersonal?.genero,
                        email: element.datos?.informacionpersonal?.email,
                        tipoDoc: element.datos?.informacionpersonal?.abreviatura,
                        doc: element.datos?.informacionpersonal?.identificacion,
                        celular: element.datos?.informacionpersonal?.celular,
                        city: res.municipio,
                        fijo: emptyObject(element.datos?.informacionpersonal?.phone),
                        observacion: element.datos?.companyrequestform?.requestText,
                        servicio: res.servicio,
                        sede: res.sede,
                        request: res.tipo,
                    }
                )
            }
        }
        datos.push(datos1);
        datos.push(datos2)
    }
    if (coleccion === "solicitudesVivienda") {
        for (var element of data) {
            var oportunida = "";
            if (element.state === "finalizado") {
                oportunida = element.oportunidad;
            }

            datos.push(
                {
                    nombre: `${emptyObject(element.step1?.datospostulante?.firstName)} ${emptyObject(element.step1?.datospostulante?.middleName)} ${emptyObject(element.step1?.datospostulante?.lastName)}`,
                    tipoDoc: emptyObject(element.step1?.datospostulante?.documentType),
                    numDoc: emptyObject(element.step1?.datospostulante?.numberId),
                    correo: emptyObject(element.step1?.datospostulante?.companyEmail),
                    teleContact: emptyObject(element.step1?.datospostulante?.companyPhone),
                    oportunidad: oportunida,
                    state: emptyObject(element.state),
                    pdf: `vivienda/${emptyObject(element.id)}`

                }
            )
        }
    }
    if (coleccion === "deportes") {
        for (var element of data) {

            datos.push(
                {
                    pdf: `deportes/${emptyObject(element.id)}`,
                    fecha: convertirFecha(element.dateTime),
                    oportunidad: emptyObject(element?.datos?.companydataform?.oportunidad),
                    tipoDocumento: emptyObject(element?.datos?.informacionpersonal?.tipoDocumentoMenor),
                    idDocumento: emptyObject(element?.datos?.informacionpersonal?.idDocumento),
                    namePostu: `${emptyObject(element?.datos?.informacionpersonal?.primerNombre)} ${emptyObject(element?.datos?.informacionpersonal?.segundoNombre)} ${emptyObject(element?.datos?.informacionpersonal?.apellidos)}`,
                    edad: emptyObject(element?.datos?.informacionpersonal?.age),

                    // expedicion: `${emptyObject(element?.datos?.informacionpersonal?.depaExp)} / ${emptyObject(element?.datos?.informacionpersonal?.cityExp)}`,
                    // nacimiento: emptyObject(element?.datos?.informacionpersonal?.nacimiento),
                    // sexo: emptyObject(element?.datos?.informacionpersonal?.sexoNa),
                    // edad: emptyObject(element?.datos?.informacionpersonal?.age),
                    // domicilio: `${emptyObject(element?.datos?.informacionpersonal?.depaPlace)} / ${emptyObject(element?.datos?.informacionpersonal?.cityPlace)}`,
                    // direccion: `${emptyObject(element?.datos?.informacionpersonal?.direccionHome)} - ${emptyObject(element?.datos?.informacionpersonal?.hoodDepor)}`,
                    // afiliadoEPS: `${emptyObject(element?.datos?.datosgenerales.entidadEps)}`,
                    // grupoSanguineo: `${emptyObject(element?.datos?.datosgenerales.grupoSanguineo)}`,
                    // medicinaPrepagada: `${emptyObject(element?.datos?.datosgenerales.medicinaPrepagada)} ${emptyObject(element?.datos?.datosgenerales.entidadMep)}`,
                    // enfermedadMenor: `${emptyObject(element?.datos?.datosgenerales.enfermedad)} ${emptyObject(element?.datos?.datosgenerales.answerEnfermedad)}`,
                    // medicamentosMenor: `${emptyObject(element?.datos?.datosgenerales.medicamento)} ${emptyObject(element?.datos?.datosgenerales.answerMedicamento)} ${emptyObject(element?.datos?.datosgenerales.dosis)}`,
                    // padre: emptyObject(element?.datos?.datosresponsable?.fatherName),
                    // madre: emptyObject(element?.datos?.datosresponsable?.motherName),
                    // email: emptyObject(element?.datos?.datosresponsable?.email),
                    // contacto: `${emptyObject(element?.datos?.datosresponsable?.cellPhone)} - ${emptyObject(element?.datos.datosresponsable?.phone)}`,

                })
        }
    }
    return datos;
}

function* initData(action) {
    const info = { selectRange: "1" }
    yield put(initialize("viviendaEstadisticForm", info));
    yield put(initialize("contactEstadisticForm", info));
    yield put(initialize("deportEstadisticForm", info));

    yield consultarSelect({ meta: { form: "viviendaEstadisticForm", field: "selectRange" }, payload: "1" });
    yield consultarSelect({ meta: { form: "contactEstadisticForm", field: "selectRange" }, payload: "1" });
    yield consultarSelect({ meta: { form: "deportEstadisticForm", field: "selectRange" }, payload: "1" });

}

function* actualizarOportunidad(action) {
    try {
        yield put(formValid({ state: " Procesando solicitud ... permanezca en línea ", error: false, ocultaBoton: true }))
        const items = yield select(getItemsPdf);
        if (items) {
            const res = yield consumeActualizaOportunidad({ id: items.step1?.datospostulante?.numberId, tipo: items.step1?.datospostulante?.documentType, idOportunidad: items.oportunidad })

            if (res) {
                yield put(formValid({ state: " Actualización exitosa ", error: false, ocultaBoton: false, finaliza: true }));
            } else {
                yield put(formValid({ state: " El proceso de actualización termino con error, no se puede actualizar la oportunidad ", error: true, ocultaBoton: false }))
            }

        }
    } catch (error) {
        console.error("exception: ", error)
    }
}

function traerLabel(action) {
    const { catalogo, id } = action;
    var result = ""
    catalogo.forEach(element => {
        if (parseInt(id) === parseInt(element.value)) {
            result = element.label;
        }
    });
    return result;
}

function traerValue(action) {
    const { catalogo, id } = action;
    var result = ""
    catalogo.forEach(element => {
        if (parseInt(element.value) === parseInt(id)) {
            result = element.id_depa;
        }
    });
    return result;
}

function homologarEstadoCivil(action) {
    const estado = [
        { id: "3", homologa: "5" },
        { id: "4", homologa: "3" },
        { id: "5", homologa: "4" },
        { id: "6", homologa: "4" },
    ]
    var id = parseInt(action.id);
    estado.forEach(element => {
        if (parseInt(element.id) === id) {
            id = element.homologa;
            return;
        }
    })
    return id;
}

function* buscaOportunidad(action) {
    const cata = yield select(getDynamicOptions);
    const jsonData = {
        "xsi": "http://www.w3.org/2001/XMLSchema-instance",
        "oportunidad": "22446",
        "valorSFV": "18,00",
        "valorSubsidioOtorgado": "16353468",
        "step1": {
            "datospostulante": {
                "companyEmail": "jairowdv@gmail.com",
                "companyPhone": "4052442",
                "currentAddress": "CR 17 C   39   51",
                "documentType": "CC",
                "hood": "POPULAR",
                "firstName": "VALVERDE SANCHEZ GEIMAR HERNEY",
                "middleName": "VALVERDE SANCHEZ GEIMAR HERNEY",
                "lastName": "VALVERDE SANCHEZ GEIMAR HERNEY",
                "namecity": "CALI-VALLE",
                "namedepartament": "76",
                "nameidPlace": "ABREGO",
                "numberId": "1006037776"
            },
            "informacionbasica": {
                "homeModeType": "2",
                "homeType": "<Z02>",
                "planHomeVivienda": "VIS",
                "postulanteType": "1",
                "subsidio": "2",
                "entidadSubsidio": ""
            },
            "informacionlaboral": {
                "laborandoData": {
                    "name": "INVERSIONES TRUAR S A S",
                    "phone": "2748544",
                    "place": "CL 21   11   21"
                },
                "subsidioData": {
                    "name": "INVERSIONES TRUAR S A S",
                    "nit": "901058057",
                    "phone": "2748544",
                    "place": "CL 21   11   21"
                }
            }
        },
        "step2": {
            "condicionHogar": {
                "birthdate": "2000-12-18",
                "companyName": "VALVERDE SANCHEZ GEIMAR HERNEY",
                "documentType": "CC",
                "ingresos": "908526",
                "jobJefeHogar": "1",
                "maritalstatusJefe": "1",
                "numDocJefe": "1006037776",
                "parentezcoJefe": "1",
                "sex": "1",
                "special": "1",
                "tipostulante": "1"
            },
            "integrantesHogar": {
                "inthogarpostulantes": [
                    {
                        "birthdatePostu": "2008-10-31",
                        "documentTypePostu": "TI",
                        "idPostu": "98012121235",
                        "ingresosMensual": "0",
                        "jobPostu": "1",
                        "maritalstatusPostu": "1",
                        "namePostu": "maria patricia alvares",
                        "nombrecajaPostu": "comfamiliar",
                        "postulantePostu": "1",
                        "relativePostu": "10",
                        "sexPostu": "2",
                        "specialPostu": "1"
                    },
                    {
                        "birthdatePostu": "2012-10-31",
                        "documentTypePostu": "RC",
                        "idPostu": "1002332436",
                        "ingresosMensual": "1200000",
                        "jobPostu": "1",
                        "maritalstatusPostu": "1",
                        "namePostu": "pedro fernandez",
                        "nombrecajaPostu": "comfenalco valle delagente",
                        "postulantePostu": "1",
                        "relativePostu": "3",
                        "sexPostu": "2",
                        "specialPostu": "1"
                    }
                ]
            },
            "totalingresos": {
                "salarioreportafiliacion": "1094017",
                "totalingresos": "2108526"
            }
        },
        "step3": {
            "informacionpostulacion": {
                "namecityPostu": "",
                "namedepartamentPostu": "05",
                "ofername": "jose",
                "projectname": "las lajas"
            },
            "modalidadvivienda": {
                "apple": "45",
                "barrioModalidad": "",
                "escritura": "4234234",
                "escriturafecha": "2021-10-31",
                "licencia": "32323232",
                "loteaddress": "palmas",
                "lotenumber": "34",
                "namecityModalidad": "CAJICA",
                "namedepartamentoModalidad": "",
                "notariaEscritura": "5",
                "predioaddress": "131231232",
                "projecttype": "1",
                "propiedadlote": "1",
                "registertestdate": "2021-10-31",
                "urbanizacion": "san ciro",
                "vencimientoLicencia": "2021-10-31"
            },
            "valorconstruccion": {
                "avaluocatastral": "900000",
                "presupuesto": "8000000",
                "valortotal": "8900000"
            }
        },
        "step4": {
            "ahorroprevio": [
                {
                    "entityA": { "label": "ALIANZA C.T.A." },
                    "openDateA": { "label": "2021-11-14" },
                    "safeValueA": { "value": "45000" },
                    "tiporecursoA": { "value": "3" }
                },
                {
                    "entityA": { "label": "PRUEBA ANA 201" },
                    "openDateA": { "label": "2021-08-30" },
                    "safeValueA": { "value": "850000" },
                    "tiporecursoA": { "value": "2" }
                },
                {
                    "entityA": { "label": "BANCO DE BOGOTA" },
                    "openDateA": { "label": "2021-10-31" },
                    "safeValueA": { "value": "60000" },
                    "tiporecursoA": { "value": "7" }
                },
                {
                    "entityA": { "label": "PORVENIR" },
                    "openDateA": { "label": "2021-09-27" },
                    "safeValueA": { "value": "670000" },
                    "tiporecursoA": { "value": "8" }
                },
                {
                    "entityA": { "label": "BANCO CAJA AGRARIA" },
                    "openDateA": { "label": "2021-09-26" },
                    "safeValueA": { "value": "70000" },
                    "tiporecursoA": { "value": "6" }
                },
                {
                    "entityA": { "label": "AV VILLAS" },
                    "openDateA": { "label": "2021-10-31" },
                    "safeValueA": { "value": "80000" },
                    "tiporecursoA": {
                        "label": "",
                        "value": "9"
                    }
                }
            ],
            "cuentasCredito": {
                "entityFuente": "48",
                "fuentesFinanciamiento": "3",
                "openDateFuente": "2021-09-15"
            },
            "financiaciontotal": {
                "cierre": "",
                "totalahorroprevio": "10878000",
                "totalrecursos": "9540000",
                "valorsubcidiado": "16353468",
                "valorvivienda": "27000000"
            },
            "recursoscomplementarios": [
                {
                    "entityR": { "label": "" },
                    "openDateR": { "label": "" },
                    "safeValueR": { "value": "70000" },
                    "tiporecursoR": { "value": "5" }
                },
                {
                    "entityR": { "label": "" },
                    "openDateR": { "label": "" },
                    "safeValueR": { "value": "80000" },
                    "tiporecursoR": { "value": "1" }
                },
                {
                    "entityR": { "label": "" },
                    "openDateR": { "label": "" },
                    "safeValueR": { "value": "9000000" },
                    "tiporecursoR": { "value": "2" }
                },
                {
                    "entityR": { "label": "" },
                    "openDateR": { "label": "" },
                    "safeValueR": { "value": "50000" },
                    "tiporecursoR": { "value": "3" }
                },
                {
                    "entityR": { "label": "" },
                    "openDateR": { "label": "" },
                    "safeValueR": { "value": "340000" },
                    "tiporecursoR": { "value": "4" }
                }
            ],
            "totalahorroprevio": { "totalAhorro": "10878000" },
            "totalrecursoscomplementarios": { "totalComple": "9540000" }
        }
    }
    const data = yield select(getOportunidadForm);

    if (data !== undefined && data?.searchOportunidad) {
        // traer oportunidad
        yield put(formValid({ state: " Procesando solicitud ... permanezca en línea ", error: false, ocultaBoton: true }));
        var items = yield consumeWSTraeOportunidad({ oportunidad: data.searchOportunidad });
        if (items) {
            yield put(formValid(false))
            var date = "";
            var date = new Date().toString().split("GMT");
            if (items?.step1?.datospostulante?.firstName) {
                items.fecha = date[0];
                items.step1.datospostulante.middleName = "";
                items.step1.datospostulante.lastName = "";
            }
            if (items?.step1?.informacionbasica?.homeType === "<Z02>") {
                items.step1.informacionbasica.homeType = "Urbano";
            }
            if (items?.step1?.informacionbasica?.homeType === "<Z03>") {
                items.step1.informacionbasica.homeType = "Rural";
            }
            if (items?.step1?.informacionbasica?.homeType === "Subsidio Rural") {
                items.step1.informacionbasica.homeType = "Rural";
            }
            if (items?.step1?.informacionbasica?.homeType === "Subsidio Urbano") {
                items.step1.informacionbasica.homeType = "Urbano";
            }
            if (items?.step2?.integrantesHogar?.inthogarpostulantes?.idPostu) {
                items.step2.integrantesHogar.inthogarpostulantes = [items.step2.integrantesHogar.inthogarpostulantes]
            }
            if (items?.step2?.condicionHogar) {

                if (items.step2.condicionHogar.sex === "1") {
                    items.step2.condicionHogar.sex = "2";
                }
                else if (items.step2.condicionHogar.sex === "2") {
                    items.step2.condicionHogar.sex = "1";
                }
                // if (items.step2.condicionHogar.maritalstatusJefe) {
                //     items.step2.condicionHogar.maritalstatusJefe = homologarEstadoCivil({ id: items.step2.condicionHogar.maritalstatusJefe });
                // }
            }
            if (items?.step2?.integrantesHogar && items?.step2?.integrantesHogar?.inthogarpostulantes?.length > 0) {
                items.step2.integrantesHogar.inthogarpostulantes.forEach(element => {
                    if (element.sexPostu === "1") {
                        element.sexPostu = "2";
                    }
                    else if (element.sexPostu === "2") {
                        element.sexPostu = "1";
                    }
                    // if (element.maritalstatusPostu) {
                    //     element.maritalstatusPostu = homologarEstadoCivil({ id: element.maritalstatusPostu });
                    // }
                });
            }
            if (items?.step4?.recursoscomplementarios) {
                items.step4.recursoscomplementarios.forEach(e => {
                    if (e.tiporecursoR.value === "1") {
                        if (items.step4?.cuentasCredito?.entityFuente) {
                            e.entityR.label = traerLabel({ id: parseInt(items.step4.cuentasCredito.entityFuente), catalogo: cata.entidad });
                            e.openDateR.label = items.step4.cuentasCredito.openDateFuente;
                        }


                    }
                });
            }

            if (items?.step1?.datospostulante?.namecity) {
                items.step1.datospostulante.namedepartament = traerLabel({ catalogo: cata.departamento, id: traerValue({ catalogo: cata.citys, id: items.step1.datospostulante.namecity }) });
                items.step1.datospostulante.namecity = traerLabel({ catalogo: cata.citys, id: items.step1.datospostulante.namecity });
            }
            else if (items?.step1?.datospostulante?.namedepartament) {
                items.step1.datospostulante.namedepartament = traerLabel({ catalogo: cata.departamento, id: items.step1.datospostulante.namedepartament });
            }

            if (items?.step3?.informacionpostulacion?.namecityPostu) {
                items.step3.informacionpostulacion.namedepartamentPostu = traerLabel({ catalogo: cata.departamento, id: traerValue({ catalogo: cata.citys, id: items.step3.informacionpostulacion.namecityPostu }) });
                items.step3.informacionpostulacion.namecityPostu = traerLabel({ catalogo: cata.citys, id: items.step3.informacionpostulacion.namecityPostu });
            }
            else if (items?.step3?.informacionpostulacion?.namedepartamentPostu) {
                items.step3.informacionpostulacion.namedepartamentPostu = traerLabel({ catalogo: cata.departamento, id: items.step3.informacionpostulacion.namedepartamentPostu });
            }

            if (items?.step3?.modalidadvivienda?.namecityModalidad) {
                items.step3.modalidadvivienda.namedepartamentoModalidad = traerLabel({ catalogo: cata.departamento, id: traerValue({ catalogo: cata.citys, id: items.step3.modalidadvivienda.namecityModalidad }) });
                items.step3.modalidadvivienda.namecityModalidad = traerLabel({ catalogo: cata.citys, id: items.step3.modalidadvivienda.namecityModalidad });
            }
            else if (items?.step3?.modalidadvivienda?.namecityModalidad) {
                items.step3.modalidadvivienda.namedepartamentoModalidad = traerLabel({ catalogo: cata.departamento, id: items.step3.modalidadvivienda.namedepartamentoModalidad });
            }

            if (items?.step4?.financiaciontotal) {
                let ahorro = 0;
                let recursos = 0;
                let subsidio = 0;
                if (items?.step4?.financiaciontotal?.totalahorroprevio?.length > 0) {
                    ahorro = parseInt(items.step4.financiaciontotal.totalahorroprevio) + ahorro;
                }
                if (items?.step4?.financiaciontotal?.totalrecursos?.length > 0) {
                    recursos = parseInt(items.step4.financiaciontotal.totalrecursos) + recursos;
                }
                if (items?.step4?.financiaciontotal?.valorsubcidiado?.length > 0) {
                    items.valorSubsidioOtorgado = items.step4.financiaciontotal.valorsubcidiado;
                    subsidio = parseInt(items.step4.financiaciontotal.valorsubcidiado) + subsidio;
                }
                items.step4.financiaciontotal.cierre = (ahorro + recursos + subsidio).toString();
            }

            if (items?.step4?.recursoscomplementarios?.entityR) {
                items.step4.recursoscomplementarios = [items.step4.recursoscomplementarios]
            }
            if (items?.step4?.ahorroprevio?.entityA) {
                items.step4.ahorroprevio = [items.step4.ahorroprevio]
            }

            if (emptyJson(items?.step4?.financiaciontotal?.cierre)) {
                items.step4.financiaciontotal.cierre = ""
            }
            items.actualizacion = "1"
            localStorage.setItem("items", JSON.stringify(items));
            yield put(saveItemsPdf(items));
            yield pdfgenera({ items });
        } else {
            yield put(formValid({ state: " El número de oportunidad ingresado no arroja resultados ", error: true, ocultaBoton: false }));
        }
    } else {
        yield put(formValid({ state: " Tienes que ingresar un número de oportunidad ", error: true, ocultaBoton: false }))
    }
    yield put(change('buscandoOportunidadForm', 'searchOportunidad', ''));
}

function* consumeWSTraeOportunidad(action) {
    const { oportunidad } = action;
    var date = "";
    var date = new Date();

    const urlConfenalco = process.env.REACT_APP_URL_COMFENALCO_ENDPOINT;
    const headers = HEADERS_WS;
    const body = {
        "requestMessageIn": {
            "header": {
                "invokerDateTime": date,
                "moduleId": "CONSULTAOPORTUNIDAD",
                "systemId": "EDUGENTE",
                "messageId": "CC16740796|122231",
                "logginData": {

                },
                "destination": {
                    "namespace": "http://co/com/comfenalcovalle/esb/ws/CRMConsultaInformacionSubsidio",
                    "name": "CRMConsultaInformacionSubsidio",
                    "operation": "execute"
                },
                "securityCredential": {

                },
                "classification": {

                }
            },
            "body": {
                "request": {
                    "consultaInfoSubsidio": {
                        "idOportunidad": oportunidad
                    }
                }
            }
        }
    }

    var resul = {}
    try {
        console.time("inicio");

        // const data = [];
        // data.push(urlConfenalco)
        // data.push(headers)
        // data.push(body)

        // const ax = (data) => {
        //     return axios({ method: "post", url: data[0], Headers: data[1], data: data[2] })
        // }
        // const res = yield call(ax, data);
        const res = yield consumeServiceGenerico({ body: body, url: urlConfenalco })
        console.log("respuesta ws oportunidad", res)
        if (res.data.responseMessageOut.header.responseStatus.statusCode === "SUCESS") {
            resul = res.data.responseMessageOut.body.response.consultaInfoSubsidioResponse;
        } else {
            resul = false;
        }

        console.timeEnd("inicio");
        return resul;
    } catch (error) {
        console.error(error, "error")
        return error;
    }
}

function* consumeServiceGenerico(action) {
    try {
        const { body, url } = action;


        const request = { urlServicio: url, body: body }
        const headers = HEADERS_WS;

        const respues = yield call(axios.post, process.env.REACT_APP_FIREBASE_SEND_CONSUME_SERVICIOS_GENERICOS, request, headers);

        return respues;
    } catch (error) {
        console.error("Function.consumeServiceGenerico", error);
        throw `Function.consumeServiceGenerico - saga.js -${error}`;
    }


}

function* pdfgenera(action) {
    const { id, items } = action;
    try {
        const resul = yield pdf(<MyDocument data={items} />).toBlob();
        var date = "";
        var date = new Date().toString().split("GMT");

        if (resul) {
            const res = yield getBase64(resul)
            const type = resul.type;
            const aux = res.split(",")
            const temp = {
                contenidoArchivo: aux[1],
                extensionArchivo: type,
                nombreArchivo: `${items.oportunidad}-actualizacion-${date[0]}`,

            }

            yield put(saveBase64Pdf(temp));
        }
    } catch (error) {
        console.error("function.pdfGenera", error)
    }
}

function* consumeActualizaOportunidad(action) {
    const { id, tipo, idOportunidad } = action;
    const messageid = tipo + id;
    var date = "";
    var date = new Date();
    const base = yield select(getBase64Pdf);
    var archivo = [];
    archivo.push(base)


    var infoOportu = {
        "idOportunidad": idOportunidad,
        "archivo": archivo,
    }


    const urlConfenalco = process.env.REACT_APP_URL_COMFENALCO_ENDPOINT;
    const token = Buffer.from('FORMULARIOSWEB:Yk2190_w', 'utf8').toString('base64')
    const autho = {};
    const headers = HEADERS_WS;
    const body = {
        "requestMessageOut": {
            "header": {
                "invokerDateTime": date,
                "moduleId": "OPORTUNIDAD",
                "systemId": "FORMULARIOSWEB",
                "messageId": messageid,
                "logginData": {
                    "sourceSystemId": "",
                    "destinationSystemId": ""
                },
                "destination": {
                    "namespace": "http://co/com/comfenalcovalle/esb/ws/CRMCreaActualizaOportunidad",
                    "name": "CRMCreaActualizaOportunidad",
                    "operation": "execute"
                },
                "securityCredential": {
                    "userName": "",
                    "userToken": ""
                },
                "classification": {
                    "classification": ""
                }
            },
            "body": {
                "request": {
                    "oportunidad": {
                        "accion": "2",
                        "infoOportunidad": infoOportu,
                    }
                }
            }
        }
    }
    var resul = {}
    try {
        console.time("inicio");
        console.log("body actualiza oportunidad", body)
        // const data = [];
        // data.push(urlConfenalco)
        // data.push(headers)
        // data.push(bodyOp)
        // const ax = (data) => {
        //     return axios({ method: "post", url: data[0], data: data[2], Headers: data[1] })
        // }
        // const res = yield call(ax, data);
        const res = yield consumeServiceGenerico({ body: body, url: urlConfenalco })
        console.log("respuesta actualiza oportunidad", res)
        if (res.data.responseMessageOut.header.responseStatus.statusCode === "SUCESS") {
            resul = true;
        } else {
            resul = false;
        }
        console.timeEnd("inicio");
        return resul;
    } catch (error) {
        console.error(error, "error")
        return error;
    }

}


function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

function* validateTypeUser(action) {

    try {
        //Busca catalogo con informacion de usuarios autorizados para funcionalidad del pdf 
        const rolParaPDF = yield getAsyncCatalog("AUTORIZED_MODULO_PDF");

        var ban = false;
        if (rolParaPDF.length > 0) {
            rolParaPDF.forEach(e => {
                if (e.correo === action.value['https://test-empresas-comf.com/auth/email'] && e.autorized) {
                    //
                    console.log("este correo esta autorizado")
                    ban = true;
                    return;
                }
            });
        }
        if (ban) {
            yield put(construirBuscarOportunidad(true))
        }
        //todo validar usuario y ocultar buscar pdfgenera
    } catch (error) {
        console.error("exception", error);
    }

}

export function saveContext(context) {
    console.log("context", context);
    saveContextAuth0(context)
}

export function* getContext() {
    //console.log("context",context);

}


export function* watchUsers() {
    yield takeLatest(LOGIN_ACTION, loginAction);
    yield takeLatest(LOGOUT_ACTION, logoutAction);
    yield takeLatest(ACTION_FILTRAR, actionFiltrar);
    yield takeLatest(REDUX_CHANGE, consultarSelect);
    yield takeLatest(BUSCAR_OPORTUNIDAD, buscaOportunidad);
    yield takeLatest(ACTUALIZAR_OPORTUNIDAD, actualizarOportunidad);
    yield takeLatest(LOAD_CATALOG, buildDynamicOptions);
    yield takeLatest(VALIDATE_TYPE_USER, validateTypeUser);

}