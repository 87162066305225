import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { CSVReader } from "react-papaparse";
import Modal from '@material-ui/core/Modal';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import { emptyObject } from "../../controller/utils";

import { PDFViewer, pdf } from '@react-pdf/renderer';
import MyDocument from '../exportpdf/mydocument';
import Button from "@material-ui/core/Button";

import { withAuth0 } from '@auth0/auth0-react';

import {
  getFormValuesStep1,
  getFormValuesStep2,
  getFormValuesStep3,
  getFormValuesStep4,
  getFormValuesStep5,
  getFormValuesStep6,
  fullFormSelector,
  getActiveStep,
  getSnackBar,
  getIdFormulario,
  getOpenModal,
  getDynamicOptions,
  getFormInitialized,
  getFormFiles,
  getCharged,
  getDynamicDisable,
  getHiddenDisable,
  getChargedValidator,
  getActivateRadio,
  getHiddenClose,
  getOptionsTable,
  getFormFirmas,
  getReloadPage,
  getContactoAdd,
  getAuthError,
  getGeneratePdf,
  getAnexosStorage,
  getInitValidate,
  getFormValid,
  getReloadPdf,
  getMessage,
  getEnabledDidabledArray,
  getRebootFiles,
  getStep3Completo,
  getModalChargedEspera,
  getFirebaseUser,
} from "./selectors";

import {
  UPDATE_FIELD_ATTRIBUTE,
  getSubmitButtonss,
  getSubmitButtons,
  SUBMIT_FORM,
  FORM_NAME_STEP1,
  FORM_NAME_STEP2,
  FORM_NAME_STEP3,
  FORM_NAME_STEP4,
  FORM_NAME_STEP5,
  FORM_NAME_STEP6,
  INCREASE_STEP,
  SALVAR_STEP,
  DECREASE_STEP,
  HANDLE_STEP,
  INCREASE_INTEGRANTES,
  DEREASE_INTEGRANTES,
  INIT_FORM_SECTION_STEP1,
  getConformacionHogar,
  STEPS_CONTENT,
  getRecursosEconomicos,
  getModalButtons,
  OPEN_FINAL_MODAL,
  SUBMIT_FINAL_FORM,
  LOAD_CATALOG,
  CREATE_CATALOG,
  INIT_ALL_FORMS,
  UPLOAD_FILE,
  REMOVE_FILE,
  getInitButtons,
  adjuntosAnexosForm,
  ARCHIVOS_ADJUNTOS,
  JURAMENTO_ACEP,
  SAVE_FORM_FILES,
  SUMAR_DATA,
  INIT_FORMS,
  getStep1Form,
  getStep3Form,
  NEW_RECURSO_ADD,
  DELETE_RECURSO_TABLE,
  ADD_INTHOGAR,
  REMOVE_FIRMA,
  UPLOAD_FIRMA,
  SAVE_FIRMA,
  CLOSE_MODAL_F,
  RENDER_PDF,
  DELETE_ANEXO,
  REQUIRED_INFORMACION_LABORAL,
  REQUIRED_INFORMACION_BASICA,
  REQUIRED_DATOS_POSTULANTE,
  REQUIRED_CONDICION_HOGAR,
  REQUIRED_INTHOGAR_POSTULANTE,
  REQUIRED_INFORMACION_POSTULACION,
  REQUIRED_MODALIDAD_VIVIENDA,
  REQUIRED_VALOR_CONSTRUCCION,
  REQUIRED_AHORRO_PREVIO,
  REQUIRED_RECURSO_COMPLEMENTARIOS,
  REQUIRED_FINANCIACION_TOTAL,
  CLOSE_MODAL_VALID,
  RELOAD_PDF,
  REQUIRED_ENTIDAD_CREDITO,
  DELETE_FIRMA,
  LOGOUT_SESION,
  TEXT_MODAL_VALIDADOR,
  getRecursosEconomicos2,
  getStep3FormV2,
  GET_FIREBASE_USER_SESSION,
} from "./constants";

import CustomForm from "../../components/materialform/customform";
import MuiAlert from "@material-ui/lab/Alert";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import { Dialog } from "primereact/dialog";
import LoginButton from "../../components/authlogin/authlogin";
import { getFromSession, saveInSession } from "../../controller/session";
import { isUndefined } from "lodash-es";

class ComponentLargeForm extends Component {

  componentWillMount() {
    const { getFirebaseUserSaga } = this.props;
    sessionStorage.clear();
    //getFirebaseUserSaga();
  }

  componentDidMount() {
    const { loadCatalog } = this.props;
    loadCatalog();
  }


  componentDidUpdate(prevProps) {

    const { activeStep, loadCatalog, dynamicOptions } = this.props;

    if (activeStep !== prevProps.activeStep) {
      // document.body.scrollTop = -100;
      // document.documentElement.scrollTop = -100;
      window.scrollTo(0, 0);
    }
    // const isAuthenticated = getFromSession('authUser');
    // const isInitializatedForm = getFromSession('formInitilizated');
    // if (isAuthenticated && isAuthenticated.user && !isInitializatedForm  && dynamicOptions && dynamicOptions.cityPostu && dynamicOptions.cityPostu.length < 1) {
    //   loadCatalog();
    // }


  }


  handleOnDrop = (data) => {
  };

  handleOnError = (err, file, inputElem, reason) => {
  };

  handleOnRemoveFile = (data) => { }


  validateStep1 = (values) => {
    let errors = {
      informacionbasica: { subsidio: "" },
      datospostulante: {},
      informacionlaboral: {},
    };
    var ban = false

    if (values) {
      const requiredInformacionBasica = REQUIRED_INFORMACION_BASICA;
      const requiredDatosPostulante = REQUIRED_DATOS_POSTULANTE;
      const requiredInformacionLaboral = REQUIRED_INFORMACION_LABORAL;

      if (values.informacionlaboral) {
        errors.informacionlaboral = {}
        if (!values.informacionlaboral.laborandoSelect || isUndefined(values.informacionlaboral.laborandoSelect)) {
          errors.informacionlaboral["laborandoSelect"] = "Campo obligatorio";
        } else {
          if (values.informacionlaboral.laborandoSelect === "otraEmpresa") {
            requiredInformacionLaboral.forEach((field) => {
              if (!values.informacionlaboral[field]) {
                errors.informacionlaboral[field] = "Campo obligatorio";
              }
            });
          } else {
            errors.informacionlaboral = {};
          }
        }



      }

      if (values.informacionbasica) {
        requiredInformacionBasica.forEach((field) => {
          if (isUndefined(values.informacionbasica[field]) || !values.informacionbasica[field]) {
            errors.informacionbasica[field] = "Campo obligatorio";
          }
        });
        if (values?.informacionbasica?.subsidio === "1") {
          if (!values.informacionbasica["entidadSubsidio"]) {
            errors.informacionbasica["entidadSubsidio"] = "Campo obligatorio";
          }
        }

      }
      if (values.datospostulante) {
        requiredDatosPostulante.forEach((field) => {
          if (isUndefined(values.datospostulante[field]) || !values.datospostulante[field]) {
            errors.datospostulante[field] = "Campo obligatorio";
          }
        });

      }

      if (values.informacionbasica && values.informacionbasica.entidadSubsidio && values.informacionbasica.entidadSubsidio.length > 250) {
        errors.informacionbasica["entidadSubsidio"] = "Longitud maxima de caracteres 250";
      }

      if (values.informacionlaboral && values.informacionlaboral.companyPhoneJob && values.informacionlaboral.companyPhoneJob.length > 250) {
        errors.informacionlaboral["companyPhoneJob"] = "Longitud maxima de caracteres 250";
      }

      if (values.informacionlaboral && values.informacionlaboral.companyJobAddress && values.informacionlaboral.companyJobAddress.length > 250) {
        errors.informacionlaboral["companyJobAddress"] = "Longitud maxima de caracteres 250";
      }

      if (values.informacionlaboral && values.informacionlaboral.companyJobName && values.informacionlaboral.companyJobName.length > 250) {
        errors.informacionlaboral["companyJobName"] = "Longitud maxima de caracteres 250";
      }


      if (
        values.datospostulante &&
        values.datospostulante.companyEmail &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
          values.datospostulante.companyEmail
        )
      ) {
        errors.datospostulante.companyEmail = "Formato de correo electrónico invalido";
      }

      if (values.informacionlaboral && values.informacionlaboral.companyPhoneJob && values.informacionlaboral.companyPhoneJob.length !== 7) {
        errors.informacionlaboral.companyPhoneJob = "Número de teléfono invalido";
      }

      if (values.datospostulante && values.datospostulante.companyPhone) {

        if (values.datospostulante.companyPhone.length > 11) {
          const aux = values.datospostulante.companyPhone.split("-")
          aux.forEach(element => {
            if (!(element.length === 7 || element.length === 10)) {
              errors.datospostulante.companyPhone = "Número de teléfono " + element + " invalido";
              return;
            }
          });
        } else {
          if (!(values.datospostulante.companyPhone.length === 7 || values.datospostulante.companyPhone.length === 10)) {
            errors.datospostulante.companyPhone = "Número de teléfono invalido";
          }
        }

      }


    }
    return errors;
  };

  validateStep2 = (values) => {
    let errors = {
      condicionHogar: {},
      inthogarpostulantes: [],

    };
    if (values) {
      const requiredCondicionHogar = REQUIRED_CONDICION_HOGAR;
      const requiredIntegrantesHogarPostulante = REQUIRED_INTHOGAR_POSTULANTE;

      if (values.condicionHogar) {
        requiredCondicionHogar.forEach((field) => {
          if (!values.condicionHogar[field]) {
            errors.condicionHogar[field] = "Campo obligatorio";
          }
        });
      }

      if (values.inthogarpostulantes) {

        var c = 0;
        requiredIntegrantesHogarPostulante.forEach((field) => {
          values.inthogarpostulantes.forEach(element => {
            if (!element[field]) {
              errors.inthogarpostulantes[field] = "Campo obligatorio";

            }
            c = c + 1;
          });
        });
      }



      let date = new Date();
      let fecha =
        date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate();

      if (values.condicionhogar && values.condicionhogar.birthdate) {
        const fechaSis = Date.parse(values.condicionhogar.birthdate);
        if (
          fechaSis < Date.parse("1900-01-01") ||
          fechaSis > Date.parse(fecha)
        ) {
          errors.condicionhogar.birthdate = "Rango de fecha invalido";
        }
      }
    }
    return errors;
  };

  validateStep3 = (values) => {
    let errors = {
      informacionpostulacion: {},
      modalidadvivienda: {},
      valorconstruccion: {},
    };
    if (values) {
      const requiredInformacionPostulacion = REQUIRED_INFORMACION_POSTULACION;
      const requiredModalidadVivienda = REQUIRED_MODALIDAD_VIVIENDA;
      const requiredValorConstruccion = REQUIRED_VALOR_CONSTRUCCION;

      if (values.valorconstruccion) {
        requiredValorConstruccion.forEach((field) => {
          if (!values.valorconstruccion[field]) {
            errors.valorconstruccion[field] = "Campo obligatorio";
          }
        });
      }

      if (values.informacionpostulacion) {
        requiredInformacionPostulacion.forEach((field) => {
          if (!values.informacionpostulacion[field]) {
            errors.informacionpostulacion[field] = "Campo obligatorio";
          }
        });
      }

      if (values.modalidadvivienda) {
        requiredModalidadVivienda.forEach((field) => {
          if (!values.modalidadvivienda[field]) {
            errors.modalidadvivienda[field] = "Campo obligatorio";
          }
        });
      }

      if (values.informacionpostulacion && values.informacionpostulacion.projectname && values.informacionpostulacion.projectname.length > 250) {
        errors.informacionpostulacion["projectname"] = "Longitud maxima de caracteres 250";
      }

      if (values.informacionpostulacion && values.informacionpostulacion.ofername && values.informacionpostulacion.ofername.length > 250) {
        errors.informacionpostulacion["ofername"] = "Longitud maxima de caracteres 250";
      }

      let date = new Date();
      let fecha =
        date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate();

      if (values.condicionvivienda && values.condicionvivienda.escriturafecha) {
        const fechaSis = Date.parse(values.condicionvivienda.escriturafecha);
        if (
          fechaSis < Date.parse("1900-01-01") ||
          fechaSis > Date.parse(fecha)
        ) {
          errors.condicionvivienda.escriturafecha = "Invalid date range";
        }
      }

      if (
        values.condicionvivienda &&
        values.condicionvivienda.registertestdate
      ) {
        const fechaSis = Date.parse(values.condicionvivienda.registertestdate);
        if (
          fechaSis < Date.parse("1900-01-01") ||
          fechaSis > Date.parse(fecha)
        ) {
          errors.condicionvivienda.registertestdate = "Invalid date range";
        }
      }
    }
    return errors;
  };

  validateStep4 = (values) => {
    let errors = {
      ahorroprevio: {},
      recursoscomplementarios: {},
      financiaciontotal: {},
      cuentasCredito: {},
    };

    if (values) {
      const requiredAhorroPrevio = REQUIRED_AHORRO_PREVIO;
      const requiredRecursoComplementarios = REQUIRED_RECURSO_COMPLEMENTARIOS;
      const requiredFinanciacionTotal = REQUIRED_FINANCIACION_TOTAL;
      const requiredEntidadCredito = REQUIRED_ENTIDAD_CREDITO;

      if (values.ahorroprevio) {
        if (values?.ahorroprevio?.tiporecursoA) {
          if (values.ahorroprevio.tiporecursoA === "9") {
            if (!values.ahorroprevio["otrorecursoA"]) {
              errors.ahorroprevio["otrorecursoA"] = "Campo obligatorio";
            }
          }
        }
        requiredAhorroPrevio.forEach((field) => {
          if (!values.ahorroprevio[field]) {
            errors.ahorroprevio[field] = "Campo obligatorio";
          }
        });
      }

      if (values.recursoscomplementarios) {
        if (values?.recursoscomplementarios?.tiporecursoR) {
          if (values.recursoscomplementarios.tiporecursoR === "5") {
            if (!values.recursoscomplementarios["otrorecursoR"]) {
              errors.recursoscomplementarios["otrorecursoR"] = "Campo obligatorio";
            }
          }
        }
        requiredRecursoComplementarios.forEach((field) => {
          if (!values.recursoscomplementarios[field]) {
            errors.recursoscomplementarios[field] = "Campo obligatorio";
          }
        });
      }

      if (values.financiaciontotal) {
        requiredFinanciacionTotal.forEach((field) => {
          if (!values.financiaciontotal[field]) {
            errors.financiaciontotal[field] = "Campo obligatorio";
          }
        });
      }
      if (values.cuentasCredito) {
        requiredEntidadCredito.forEach((field) => {
          if (!values.cuentasCredito[field]) {
            errors.cuentasCredito[field] = "Campo obligatorio";
          }
        });
      }



      let date = new Date();
      let fecha =
        date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate();

      if (values.ahorroprevio && values.ahorroprevio.opendate) {
        const fechaSis = Date.parse(values.ahorroprevio.opendate);
        if (
          fechaSis < Date.parse("1900-01-01") ||
          fechaSis > Date.parse(fecha)
        ) {
          errors.ahorroprevio.opendate = "Invalid date range";
        }
      }

      if (
        values.recursocomplementariosection &&
        values.recursocomplementariosection.opendate
      ) {
        const fechaSis = Date.parse(
          values.recursocomplementariosection.opendate
        );
        if (
          fechaSis < Date.parse("1900-01-01") ||
          fechaSis > Date.parse(fecha)
        ) {
          errors.recursocomplementariosection.opendate = "Invalid date range";
        }
      }
    }
    return errors;
  };
  validateStep6 = (values) => {
    var errors = {
      juramentoPartes: {},
    };

    if (values) {

      const juramentopartes = [
        "juramentoCheck",
      ];
      if (values?.juramentoPartes) {
        if (!values.juramentoPartes["juramentoCheck"] || values.juramentoPartes["juramentoCheck"] === false) {
          errors.juramentoPartes.juramentoCheck =
            "Tiene que aceptar el Juramento";
        }
      }

    }

    return errors;
  };

  showSnackBar(open, message) {
    return (
      <Snackbar open={open} autoHideDuration={1} className="ocultar">
        <Alert severity="success">{message}</Alert>
      </Snackbar>
    );
  }

  modalSubmitConfirmation(props) {
    const { openModal, closeModal, submitFinalForm, saveFormFiles, formFiles } = props;

    return (
      <Dialog
        visible={openModal}
        header="Finalizar formulario"
        style={{ width: "50vw" }}
        modal
        onHide={() => closeModal}
      >
        <CustomForm
          submitable={false}
          items={[]}
          formName="submitConfirmation"
          handleSubmit={() => submitFinalForm(formFiles)}
          buttons={getModalButtons(
            () => closeModal()
          )}
          className="modal-submit-basica-form"
        />
      </Dialog>
    );
  }
  modalSubmitEspera(props) {
    const { reloadPage,
      contactoAdd,
      error, closeModal, initReload } = props;

    return (
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open
      >
        <div className="messageSucessViv">
          <React.Fragment>
            {error ? (
              <Alert className="alert danger" >
                {contactoAdd}
              </Alert>
            ) : (
              <Alert className="alert success" >
                {contactoAdd}
              </Alert>
            )}
          </React.Fragment>
          {reloadPage === false ?
            <div>
              <CircularProgress />
            </div>
            :
            <div>
              {error ?
                <Button
                  className='buttonFinalizar'
                  variant="text"
                  color="secondary"
                  onClick={() => closeModal()}
                >
                  Volver
                </Button> :
                <Button
                  className='buttonFinalizar'
                  variant="text"
                  color="default"
                  onClick={() => initReload()}
                >
                  Finalizar
                </Button>
              }

            </div>
          }
        </div>
      </Modal>
    );
  }
  modalSubmitValid(props) {
    const { formValid, closeModalValid } = props;

    return (
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open
      >
        <div className="messageSucess">
          {formValid && formValid.error ?
            <React.Fragment>
              <Alert className="alertsucessBorrador" >
                {formValid.mes}
              </Alert>
              {formValid && formValid.errorMo ?
                <div>

                  <CircularProgress />
                </div>
                : ''}
            </React.Fragment>
            :
            <React.Fragment>
              <Alert className="alert danger" >
                {formValid.mes ? formValid.mes : formValid}
              </Alert>
            </React.Fragment>
          }

          {formValid && formValid.errorMo ? '' :
            <div>
              <Button
                className='buttonFinalizar'
                variant="text"
                color="default"
                onClick={() => closeModalValid()}
              >
                Continuar
              </Button>

            </div>
          }

        </div>
      </Modal>
    );
  }

  modalEsperaCharged() {

    return (
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open
      >
        <div className="modalEsperaCharged">
          <CircularProgress style={{ width: '70px', height: '70px' }} />
        </div>
      </Modal>
    );
  }


  getStepContent(props) {
    const {
      activeStep,
      increaseStep,
      decreaseStep,
      openSubmitModal,
      formFiles,
      formFirmas,
      dynamicOptions,
      optionsInthogar,
      dynamicDisable,
      hiddenDisable,
      hiddenClose,
      anexosStorage,
      salvarStep,
      handleUpload,
      handleSuma,
      handleRemove,
      handleRemoveF,
      handleUploadF,
      charged,
      activateRadio,
      enableDisableArray,
      handleAdd,
      optionsTable,
      deleteRecurso,
      deleteAnexo,
      deleteFirma,
      addInthogar,
      initValid,
      rebootFiles,
      step3completo,
    } = props;

    switch (activeStep) {
      case 0:
        return (
          <React.Fragment>
            <CustomForm
              submitable={false}
              items={JURAMENTO_ACEP}
              formName={FORM_NAME_STEP6}
              validations={this.validateStep6}
              handleSubmit={() => increaseStep()}
              buttons={getInitButtons(() => increaseStep)}
              charged={charged}
              anexosStorage={anexosStorage}
              dynamicOptions={dynamicOptions}
              enableDisableArray={enableDisableArray}
              dynamicDisable={dynamicDisable}
              className="people-form"
            />
          </React.Fragment>
        );
      case 1:
        return (
          <React.Fragment>
            <CustomForm
              submitable={false}
              items={getStep1Form()}
              activateRadio={activateRadio}
              validations={this.validateStep1}
              formName={FORM_NAME_STEP1}
              handleSubmit={() => increaseStep()}
              initValid={initValid}
              buttons={getSubmitButtonss(
                () => decreaseStep(),
                () => increaseStep()
              )}
              charged={charged}
              dynamicOptions={dynamicOptions}
              dynamicDisable={dynamicDisable}
              enableDisableArray={enableDisableArray}
              className="informacion-basica-form"
              hiddenDisable={hiddenDisable}
              optionsTable={optionsTable}
            />
          </React.Fragment>
        );
      case 2:
        return (
          <React.Fragment>
            <CustomForm
              submitable={false}
              items={getConformacionHogar(handleRemoveF, handleUploadF,
                handleSuma)}
              hiddenDisable={hiddenDisable}
              addInthogar={addInthogar}
              handleSuma={handleSuma}
              enableDisableArray={enableDisableArray}
              deleteFirma={deleteFirma}
              initValid={initValid}
              formName={FORM_NAME_STEP2}
              validations={this.validateStep2}
              handleSubmit={() => increaseStep(formFirmas)}
              buttons={getSubmitButtonss(
                () => decreaseStep(),
                () => decreaseStep()
              )}
              charged={charged}
              optionsInthogar={optionsInthogar}
              dynamicOptions={dynamicOptions}
              dynamicDisable={dynamicDisable}
              className="conformacion-hogar-form"

            />
          </React.Fragment>
        );
      case 3:
        return (
          <React.Fragment>
            <CustomForm
              submitable={false}
              items={step3completo ? getStep3Form(activateRadio) : getStep3FormV2(activateRadio)}
              initValid={initValid}
              formName={FORM_NAME_STEP3}
              validations={this.validateStep3}
              handleSubmit={() => increaseStep()}
              buttons={getSubmitButtonss(
                () => decreaseStep(),
                () => decreaseStep()
              )}
              charged={charged}
              dynamicOptions={dynamicOptions}
              dynamicDisable={dynamicDisable}
              hiddenDisable={hiddenDisable}
              className="postulacion-form"
            />
          </React.Fragment>
        );
      case 4:
        return (
          <React.Fragment>
            <CustomForm
              submitable={false}
              items={rebootFiles ? getRecursosEconomicos2(handleAdd) : getRecursosEconomicos(handleAdd)}
              handleSuma={handleSuma}
              hiddenClose={hiddenClose}
              deleteRecurso={deleteRecurso}
              hiddenDisable={hiddenDisable}
              formName={FORM_NAME_STEP4}
              validations={this.validateStep4}
              handleSubmit={() => increaseStep()}
              buttons={getSubmitButtonss(
                () => decreaseStep(),
                () => decreaseStep()
              )}
              rebootFiles={rebootFiles}
              charged={charged}
              dynamicOptions={dynamicOptions}
              initValid={initValid}
              dynamicDisable={dynamicDisable}
              className="people-form"
            />
          </React.Fragment>
        );
      case 5:
        return (
          <React.Fragment>
            <CustomForm
              submitable={false}
              items={adjuntosAnexosForm(handleUpload,
                handleRemove)}
              handleUpload={handleUpload}
              handleRemove={handleRemove}
              deleteAnexo={deleteAnexo}
              formName={FORM_NAME_STEP5}
              handleSubmit={() => openSubmitModal()}
              buttons={getSubmitButtons(
                () => decreaseStep(),
                () => salvarStep(formFiles),
              )}
              formTitle={ARCHIVOS_ADJUNTOS}
              charged={charged}
              dynamicOptions={dynamicOptions}
              dynamicDisable={dynamicDisable}
              className="people-form"
            />
          </React.Fragment>
        );


      default:
        return (
          <MuiAlert elevation={6} variant="filled" severity="info">
            Formulario en construcción
          </MuiAlert>
        );
    }
  }

  render() {
    const {
      activeStep,
      handleStep,
      showSnackBar,
      createCatalog,
      modalChargedEspera,
      loadCatalog,
      chargedValidator,
      generateAPdf,
      contactoAdd,
      formValid,
      reloadPdf,
      firebaseUser,
    } = this.props;

    return (
      <div className="App auth-container">
        <LoginButton
          firebaseUser={firebaseUser}
          urlRedirect={`${window.location.origin}/vivienda`}
          callbackPath="/vivienda"
        >
          {chargedValidator === true ?
            <Modal
              disablePortal
              disableEnforceFocus
              disableAutoFocus
              open
              className="modalValidador"
            >

              <div className="validador_div" >
                {TEXT_MODAL_VALIDADOR}
                <CircularProgress />
              </div>


            </Modal>
            : ""}
          <React.Fragment >
            <Stepper className="stepper-form" activeStep={activeStep}>
              {STEPS_CONTENT.map((label, index) => {
                const stepProps = {};
                const labelProps = {};

                return (
                  <Step key={label} {...stepProps}>
                    <StepButton
                      onClick={() => handleStep(index)}
                      {...labelProps}
                    >
                      {label}
                    </StepButton>
                  </Step>
                );
              })}
            </Stepper>

            {this.getStepContent(this.props)}
            {modalChargedEspera ? this.modalEsperaCharged() : ""}
            {showSnackBar ? this.showSnackBar(
              showSnackBar,
              "Sucess"
            ) : ""}
            {this.modalSubmitConfirmation(this.props)}
            {contactoAdd ? this.modalSubmitEspera(this.props) : ''}
            {formValid ? this.modalSubmitValid(this.props) : ''}
            {generateAPdf.state && reloadPdf ? window.open("exportpdf/vivienda/" + generateAPdf.id, "Popup", "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0,") : ""}

            {reloadPdf ?
              // window.location.replace("https://pruebas.comfenalcovalle.com.co/delagentewebtest/1/1") :
              window.location.replace("https://www.comfenalcovalle.com.co/") :
              ""}

            {false && (
              <CSVReader
                onDrop={createCatalog}
                onError={this.handleOnError}
                noDrag
                addRemoveButton
                onRemoveFile={this.handleOnRemoveFile}
              >
                <span>Click to upload.</span>
              </CSVReader>
            )}
          </React.Fragment>
        </LoginButton>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    formValuesStep1: getFormValuesStep1(state),
    formValuesStep2: getFormValuesStep2(state),
    formValuesStep3: getFormValuesStep3(state),
    formValuesStep4: getFormValuesStep4(state),
    formValuesStep5: getFormValuesStep5(state),
    formValuesStep6: getFormValuesStep6(state),
    formValues: fullFormSelector(state),
    activeStep: getActiveStep(state),
    showSnackBar: getSnackBar(state),
    idFormulario: getIdFormulario(state),
    openModal: getOpenModal(state),
    dynamicOptions: getDynamicOptions(state),
    optionsInthogar: getDynamicOptions(state),
    dynamicDisable: getDynamicDisable(state),
    activateRadio: getActivateRadio(state),
    optionsTable: getOptionsTable(state),
    hiddenDisable: getHiddenDisable(state),
    hiddenClose: getHiddenClose(state),
    formInitialized: getFormInitialized(state),
    formFiles: getFormFiles(state),
    generateAPdf: getGeneratePdf(state),
    formFirmas: getFormFirmas(state),
    charged: getCharged(state),
    message: getMessage(state),
    chargedValidator: getChargedValidator(state),
    reloadPage: getReloadPage(state),
    contactoAdd: getContactoAdd(state),
    error: getAuthError(state),
    anexosStorage: getAnexosStorage(state),
    initValid: getInitValidate(state),
    formValid: getFormValid(state),
    reloadPdf: getReloadPdf(state),
    enableDisableArray: getEnabledDidabledArray(state),
    rebootFiles: getRebootFiles(state),
    step3completo: getStep3Completo(state),
    modalChargedEspera: getModalChargedEspera(state),
    firebaseUser: getFirebaseUser(state),
  };
};

const mapDispachToProps = (dispatch) => {
  return {
    updateAttributes: (value) =>
      dispatch({ type: UPDATE_FIELD_ATTRIBUTE, value }),
    increaseStep: (value) => dispatch({ type: INCREASE_STEP, value }),
    salvarStep: (value) => dispatch({ type: SALVAR_STEP, value }),
    decreaseStep: (value) => dispatch({ type: DECREASE_STEP, value }),
    handleStep: (value) => dispatch({ type: HANDLE_STEP, value }),
    logoutSesion: (value) => dispatch({ type: LOGOUT_SESION, value }),
    increaseInt: (value) => dispatch({ type: INCREASE_INTEGRANTES, value }),
    decreaseInt: (value) => dispatch({ type: DEREASE_INTEGRANTES, value }),
    updateField: (value) => dispatch({ type: "UPDATE_SINGLE_FIELD", value }),
    submitForm: (value) => dispatch({ type: SUBMIT_FORM, value }),
    closeModal: (value) => dispatch({ type: CLOSE_MODAL_F, value }),
    closeModalValid: (value) => dispatch({ type: CLOSE_MODAL_VALID, value }),
    openSubmitModal: (value) => dispatch({ type: OPEN_FINAL_MODAL, value }),
    saveFormFiles: (value) => dispatch({ type: SAVE_FORM_FILES, value }),
    saveFormFirmas: (value) => dispatch({ type: SAVE_FIRMA, value }),
    submitFinalForm: (value) => dispatch({ type: SUBMIT_FINAL_FORM, value }),
    loadCatalog: (value) => dispatch({ type: LOAD_CATALOG, value }),
    createCatalog: (value) => dispatch({ type: CREATE_CATALOG, value }),
    initFormStep1: (value) => dispatch({ type: INIT_FORM_SECTION_STEP1, value }),
    initAllForms: (value) => dispatch({ type: INIT_ALL_FORMS, value }),
    initForms: (value) => dispatch({ type: INIT_FORMS, value }),
    handleAdd: (value) => dispatch({ type: NEW_RECURSO_ADD, value }),
    addInthogar: (value) => dispatch({ type: ADD_INTHOGAR, value }),
    handleRemove: (value) => dispatch({ type: REMOVE_FILE, value }),
    handleUpload: (value) => dispatch({ type: UPLOAD_FILE, value }),
    handleRemoveF: (value) => dispatch({ type: REMOVE_FIRMA, value }),
    handleUploadF: (value) => dispatch({ type: UPLOAD_FIRMA, value }),
    handleSuma: (value) => dispatch({ type: SUMAR_DATA, value }),
    deleteRecurso: (value) => dispatch({ type: DELETE_RECURSO_TABLE, value }),
    renderPdf: (value) => dispatch({ type: RENDER_PDF, value }),
    deleteAnexo: (value) => dispatch({ type: DELETE_ANEXO, value }),
    deleteFirma: (value) => dispatch({ type: DELETE_FIRMA, value }),
    initReload: (value) => dispatch({ type: RELOAD_PDF, value }),
    getFirebaseUserSaga: (value) => dispatch({ type: GET_FIREBASE_USER_SESSION, value }),
  };
};

export default connect(mapStateToProps, mapDispachToProps)(ComponentLargeForm);
