import { LOGIN_SUCESS, LOGIN_ERROR,CARGAR_EVENT, DATA_GRAPH1, DATA_GRAPH2, DATA_GRAPH3, HIDDEN_DISABLES, CLOSE_MODAL_VALID, FORM_VALID, LOAD_CATALOG_SUCCESS, CARGANDO_MODAL, SAVE_ITEMS_PDF, SAVE_BASE64_PDF, CONSTRUIR_BUSCAR_OPORTUNIDAD, SAVE_CONTEXT_AUTH0 } from "./constants";

export function loginSuccess (payload) {
    return {
        type: LOGIN_SUCESS,
        payload
    };
}

export function loginError (payload) {
    return {
        type: LOGIN_ERROR,
        payload
    };
}

export function cargarEvent (payload) {
    return {
        type: CARGAR_EVENT,
        payload
    };
}

export function dataGraph1 (payload) {
    return {
        type: DATA_GRAPH1,
        payload
    };
}

export function dataGraph3 (payload) {
    return {
        type: DATA_GRAPH3,
        payload
    };
}
export function dataGraph2 (payload) {
    return {
        type: DATA_GRAPH2,
        payload
    };
}

export function hiddenDisable (payload) {
    return {
        type: HIDDEN_DISABLES,
        payload
    };
}

export function closeModalValid (payload) {
    return {
        type: CLOSE_MODAL_VALID,
        payload
    };
}

export function formValid (payload) {
    return {
        type: FORM_VALID,
        payload
    };
}

export function loadCatalogSuccess (payload) {
    return {
        type: LOAD_CATALOG_SUCCESS,
        payload
    };
}


export function cargandoModal (payload) {
    return {
        type: CARGANDO_MODAL,
        payload
    };
}

export function saveItemsPdf (payload) {
    return {
        type: SAVE_ITEMS_PDF,
        payload
    };
}

export function saveBase64Pdf (payload) {
    return {
        type: SAVE_BASE64_PDF,
        payload
    };
}

export function construirBuscarOportunidad (payload) {
    return {
        type: CONSTRUIR_BUSCAR_OPORTUNIDAD,
        payload
    };
}

export function saveContextAuth0 (payload) {
    return {
        type: SAVE_CONTEXT_AUTH0,
        payload
    };
}

