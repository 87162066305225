import { createStructuredSelector } from 'reselect';
import { formValueSelector, getFormValues, } from 'redux-form' // ES6;
import { FORM_NAME_STEP1, FORM_NAME_STEP2, FORM_NAME_STEP3, FORM_NAME_STEP4, FORM_NAME_STEP5, FORM_NAME_STEP6 } from './constants';

const selector = formValueSelector(FORM_NAME_STEP1);
export const getFormValuesStep1 = (state) => getFormValues(FORM_NAME_STEP1)(state);
export const getFormValuesStep2 = (state) => getFormValues(FORM_NAME_STEP2)(state);
export const getFormValuesStep3 = (state) => getFormValues(FORM_NAME_STEP3)(state);
export const getFormValuesStep4 = (state) => getFormValues(FORM_NAME_STEP4)(state);
export const getFormValuesStep5 = (state) => getFormValues(FORM_NAME_STEP5)(state);
export const getFormValuesStep6 = (state) => getFormValues(FORM_NAME_STEP6)(state);
export const getFormId = (state) => FORM_NAME_STEP1;
export const getActiveStep = (state) => state.localReducer.step;
export const getFormValid = (state) => state.localReducer.formValid;
export const getSnackBar = (state) => state.localReducer.showSnackBar;
export const getIdFormulario = (state) => state.localReducer.idFormulario;
export const getOpenModal = (state) => state.localReducer.openModal;
export const getDataEmpSubsidio = (state) => state.localReducer.dataEmpSubsidio;
export const getDynamicOptions = (state) => state.localReducer.dynamicOptions;
export const getReferenceFiles = (state) => state.localReducer.referenceFiles;
export const getDynamicDisable = (state) => state.localReducer.dynamicDisable;
export const getActivateRadio = (state) => state.localReducer.activateRadio;
export const getHiddenDisable = (state) => state.localReducer.hiddenDisable;
export const getContactoAdd = (state) => state.localReducer.contactoAdd;
export const getAuthError = (state) => state.localReducer.error;
export const getReloadPage = (state) => state.localReducer.reloadPage;
export const getHiddenClose = (state) => state.localReducer.hiddenClose;
export const getFormInitialized = (state) => state.localReducer.formInitialized;
export const getUserId = (state) => state.localReducer.userid;
export const getFormFiles = (state) => state.localReducer.formFiles;
export const getFormFirmas = (state) => state.localReducer.formFirmas;
export const getCharged = (state) => state.localReducer.charged;
export const getCatalogosCargados = (state) => state.localReducer.catalogos;
export const getChargedValidator = (state) => state.localReducer.chargedValidator;
export const getOptionsTable = (state) => state.localReducer.optionsTable;
export const getAddRecursoAhorro = (state) => state.localReducer.addRecursoAhorro;
export const getAddRecursoComplementario = (state) => state.localReducer.addRecursoComple;
export const getbase64Add = (state) => state.localReducer.base64Add;
export const getHoodId = (state) => state.localReducer.hoodId;
export const getInitValidate = (state) => state.localReducer.initValid;
export const getAnexosStorage = (state) => state.localReducer.anexosStorage;
export const getRefFiles = (state) => state.localReducer.refFiles;
export const getRefFirmas = (state) => state.localReducer.refFirmas;
export const getGeneratePdf = (state) => state.localReducer.generatePdf;
export const getReloadPdf = (state) => state.localReducer.reloadPdf;
export const getStep3Completo = (state) => state.localReducer.step3completo;
export const getMessage = (state) => state.localReducer.message;
export const getItemSaved = (state) => state.localReducer.itemSaved;
export const getOptionsInthogar = (state) => state.localReducer.optionsInthogar;
export const getEnabledDidabledArray = (state) => state.localReducer.enableDisableArray;
export const getRebootFiles = (state) => state.localReducer.rebootFiles;
export const getCatalogoCiclos = (state) => state.localReducer.catalogoCiclos;
export const getIntegrantesObligatorios = (state) => state.localReducer.integrantesObligatorios;
export const getModalChargedEspera = (state) => state.localReducer.modalChargedEspera;
export const getFirebaseUser = (state) => state.localReducer.firebaseUser;
export const getFirebaseUserInfo = (state) => state.localReducer.firebaseUserInfo;

export const getCompanyValuesStep1 = (state) => getFormValues("informacionBasicaStep1")(state);
export const getCompanyValuesStep2 = (state) => getFormValues("conformacionHogarStep2")(state);

export const getCompanyValuesStep4 = (state) => getFormValues("recursosEconomicosStep4")(state);
export const getCompanyValuesStep3 = (state) => getFormValues("postulacionStep3")(state);

export const getUpdateIngreStep2 = (state) => state.localReducer.ingreStep2;
export const getUpdateIngreStep4 = (state) => state.localReducer.ingreStep4;

export const favoriteColorValue = (state) => selector(state, 'favoriteColor');

export const fullFormSelector = createStructuredSelector({
    step1: getFormValuesStep1,
    step2: getFormValuesStep2,
    step3: getFormValuesStep3,
    step4: getFormValuesStep4,
    step5: getFormValuesStep5,
    step6: getFormValuesStep6
});