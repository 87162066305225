export const LOGIN_ACTION = '@admin/loginAction';
export const LOGOUT_ACTION = '@admin/logoutAction';
export const CHANGE_TAB_ACTION = '@admin/changeTabAction';

export const LOGIN_SUCESS = '@admin/loginSuccess';
export const LOGIN_ERROR = '@admin/loginError';

export const CARGAR_EVENT = '@admin/cargarEvent';
export const HIDDEN_DISABLES = '@admin/hiddenDisable';

export const LOGIN_FORM_NAME = 'adminLoginForm';
export const COMPONENT_TABS = ['Vivienda', 'Contactos', 'Recreación'];
export const ACTION_FILTRAR = "@admin/actionFiltrar"
export const REDUX_CHANGE = '@@redux-form/CHANGE';
export const DATA_GRAPH1 = "@admin/dataGraph1";
export const DATA_GRAPH2 = "@admin/dataGraph2";
export const DATA_GRAPH3 = "@admin/dataGraph3";
export const CLOSE_MODAL_VALID = "@admin/closeModalValid";
export const FORM_VALID = "@admin/formValid";
export const LOAD_CATALOG_SUCCESS = "@admin/loadCatalogSuccess";
export const LOAD_CATALOG = "@admin/loadCatalog";
export const CARGANDO_MODAL = "@admin/cargandoModal";
export const SAVE_CONTEXT_AUTH0 = "@admin/saveContext";

export const BUSCAR_OPORTUNIDAD = "@admin/buscarOportunidad";
export const VISUALIZAR_PDF = "@admin/visualizarPdf";
export const ACTUALIZAR_OPORTUNIDAD = "@admin/actualizarOportunidad";
export const SAVE_ITEMS_PDF= "@admin/saveItemsPdf";
export const SAVE_BASE64_PDF= "@admin/saveBase64Pdf";
export const CLOSE_DIALOG_PDF= "@admin/closeDialogPdf";
export const VALIDATE_TYPE_USER = "@admin/validateTypeUser";
export const CONSTRUIR_BUSCAR_OPORTUNIDAD = "@admin/construirBuscarOportunidad";

export const TITLE_TABLE_CONTACT = "Listado de PQRS tipo personas";
export const TITLE_TABLE_EMPRESA = "Listado de PQRS tipo empresas";
export const TITLE_PIE_CONTACT = "Relación de Servicios solicitados";
export const TITLE_GRAF1_CONTACT = "Tipos de Solicitud ";
export const TITLE_GRAF2_CONTACT = "Cantidad de peticiones de tipo Persona y Empresa ";

export const TITLE_TABLE_DEPORTE = "Listado de solicitudes para subsidio de deportes";
export const TITLE_PIE_DEPORTE = "Relación de solicitudes por munucipio";
export const TITLE_GRAF1_DEPORTE = "Tipos de Solicitud academia vs vacaciones ";

export const TITLE_TABLE_VIVIENDA = "Lista de datos postulación";
export const TITLE_GRAF1_VIVIENDA = "Solicitudes en estado borrador y finalizado, por tipo de vivienda ";
export const TITLE_GRAF2_VIVIENDA = "Relación de solicitudes por modalidad de vivienda";
export const TITLE_GRAF3_VIVIENDA = "Relación de solicitudes por tipo de postulante";
export const TITLE_GRAF4_VIVIENDA = "Relación de solicitudes por plan de vivienda";
export const TITLE_GRAF5_VIVIENDA = "Solicitudes en estado borrador y finalizado";


export const HEADERS_WS ={
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
  'Access-Control-Allow-Credentials': 'true',
  "Authorization": 'Basic Rk9STVVMQVJJT1NXRUI6WWsyMTkwX3c=',
  "Access-Control-Request-Headers": "access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,auth,authorization,cache-control,content-type,mode",
  'accept': "application/json, text/plain, */*",
  "content-type": "application/json;charset=iso-8859-1",
  "Cache-Control": "no-cache",
  'mode': "opaque ",
  auth: {
    username: "FORMULARIOSWEB",
    password: 'Yk2190_w'
  }
}

export const TABLE_OPTIONS_PERSONA = [
  { field: "tipo", header: "Tipo pqrs" },
  { field: "tiket", header: "Tiket" },
  { field: "tiketError", header: "Petición fallida" },
  { field: "fecha", header: "Fecha registro" },
  { field: "name", header: "Nombre de contacto" },
  { field: "apellido", header: "Apellido" },
  { field: "nacimiento", header: "Nacimiento" },
  { field: "tipoDoc", header: "Tipo documento" },
  { field: "doc", header: "Numero documento" },
  { field: "genero", header: "Genero" },
  { field: "email", header: "Correo electrónico" },
  { field: "celular", header: "Número celular" },
  { field: "fijo", header: "Teléfono fijo" },
  { field: "departamento", header: "Departamento" },
  { field: "city", header: "Municipio" },
  { field: "request", header: "Tipo de Solicitud " },
  { field: "servicio", header: "Relacionada con " },
  //{ field: "motivo", header: "Motivo " },
  { field: "sede", header: "Sede " },
  { field: "observacion", header: "Observación " },
];
export const TABLE_OPTIONS_EMPRESA = [
  { field: "tipo", header: "Tipo pqrs" },
  { field: "tiket", header: "Tiket" },
  { field: "tiketError", header: "Petición fallida" },
  { field: "tipoIdEmpresa", header: "Identificación Empresa " },
  { field: "idEmpresa", header: "Número de identificación " },
  { field: "razon", header: "Razón social " },
  { field: "celEmpresa", header: "Teléfono celular" },
  { field: "fijoEmpresa", header: "Teléfono fijo" },
  { field: "emailEmpresa", header: "Correo electrónico empresa" },
  { field: "depaEmpresa", header: "Departamento de domicilio" },
  { field: "cityEmpresa", header: "Municipio de domicilio" },
  { field: "sizeEmpresa", header: "Tamaño empresa" },
  { field: "name", header: "Nombre contacto" },
  { field: "apellido", header: "Apellidos" },
  { field: "nacimiento", header: "Nacimiento" },
  { field: "tipoDoc", header: "Tipo documento" },
  { field: "doc", header: "Numero documento" },
  { field: "genero", header: "Genero" },
  { field: "email", header: "Correo electrónico" },
  { field: "celular", header: "Número celular" },
  { field: "fijo", header: "Teléfono fijo" },
  { field: "departamento", header: "Departamento" },
  { field: "city", header: "Municipio" },
  { field: "cargo", header: "Cargo " },
  { field: "request", header: "Tipo de Solicitud " },
  { field: "servicio", header: "Relacionada con " },
  { field: "motivo", header: "Motivo " },
  { field: "sede", header: "Sede " },
  { field: "observacion", header: "Observación " },
];

export const TABLE_INTHOGAR = [
  { field: "nombreInth", header: "Nombres y apellidos" },
  { field: "nacimiento", header: "Fecha de nacimiento" },
  { field: "tipoDoc", header: "Tipo de documento" },
  { field: "numDoc", header: "Número de documento" },
  { field: "sexo", header: "Sexo" },
  { field: "estadoCivil", header: "Estado civil" },
  { field: "parentesco", header: "Parentesco" },
  { field: "ocupacion", header: "Ocupación " },
  { field: "condicionJefe", header: "Condición especial" },
  { field: "tipoPostu", header: "Tipo postulante" },
  { field: "ingresos", header: "Ingresos" },
  { field: "caja", header: "caja de compensación" },
]

export const TABLE_OPTIONS_VIVIENDA = [
  { field: "pdf", header: "Ver PDF" },
  { field: "state", header: "Estado" },
  { field: "oportunidad", header: "N° Oportunidad" },
  { field: "nombre", header: "Nombres y apellidos" },
  { field: "tipoDoc", header: "Tipo de documento" },
  { field: "numDoc", header: "Número de documento" },
  { field: "correo", header: "Correo electrónico" },
  { field: "teleContact", header: "Teléfono de contacto" },
  
];

export const TABLE_OPTIONS_DEPORTES = [
  { field: "pdf", header: "Ver PDF" },
  { field: "fecha", header: "Fecha solicitud" },
  { field: "tipoDocumento", header: "Tipo de documento" },
  { field: "idDocumento", header: "Número de documento" },
  { field: "oportunidad", header: "N° Oportunidad" },
  { field: "namePostu", header: "Nombres postulante" },  
  { field: "edad", header: "Edad" },
  // { field: "domicilio", header: "Lugar de domicilio" },
  // { field: "direccion", header: "Dirección de domicilio" },
  // { field: "afiliadoEPS", header: "Afiliado EPS" },
  // { field: "grupoSanguineo", header: "Grupo sanguineo" },
  // { field: "medicinaPrepagada", header: "Medicina prepagada" },
  // { field: "enfermedadMenor", header: "Enfermedad menor" },
  // { field: "medicamentosMenor", header: "Medicamentos menor" },
  // { field: "padre", header: "Padre menor" },
  // { field: "madre", header: "Madre menor" },
  // { field: "email", header: "Email" },
  // { field: "contacto", header: "Teléfono de contacto" },

]

export const DATA_SERVICES = [
  { label: "AFILIACION ACTUALIZACION Y CERTIFICADOS", value: "12SCF", color: "#084C61" },
  { value: "10SCF", label: "AGENCIA DE EMPLEO", color: "#DB504A" }
  , { value: "9SCF", label: "AGENCIA DE VIAJES", color: "#E3B505" }
  , { value: "14SCF", label: "APORTES EMPRESAS", color: "#4F6D7A" }
  , { label: "ATENCION Y GESTION EMPRESARIAL", value: "2SCF", color: "#56A3A6" }
  , { value: "6SCF", label: "CONVENIOS Y ALIANZAS", color: "#B2B09B" }
  , { label: "CREDITOS Y ANTICIPO SUBSIDIO", value: "16SCF", color: "#43AA8B" }
  , { label: "EDUCACION Y CULTURA", value: "7SCF", color: "#254441" }
  , { label: "HOTELES COMFENALCO VALLE", value: "8SCF", color: "#ED1C24" }
  , { label: "MANTENIMIENTO Y SEGURIDAD  INSTALACIONES", value: "5SCF", color: "#235789" }
  , { value: "4SCF", label: "PROTECCION DATOS PERSONALES", color: "#C7D6D5" }
  , { value: "3SCF", label: "RECREACION Y DEPORTES", color: "#F7ACCF" }
  , { label: "SERVICIO Y CANALES DE ATENCION", value: "1SCF", color: "#392759" }
  , { value: "13SCF", label: "SUBSIDIO FAMILIAR EN DINERO", color: "#7A5C61" }
  , { label: "SUCURSAL VIRTUAL", value: "15SCF", color: "#E3D87E" }
  , { label: "VIVIENDA", value: "11SCF", color: "#A3320B" }

]

export const DATA_TIPO_POS = [
  { label: "DEPENDIENTE", value: "1", color: "#084C61" },
  { value: "PENSIONADO", label: "2", color: "#DB504A" }
  , { value: "MADRE COMUNITARIA", label: "3", color: "#E3B505" }
  , { value: "INDEPENDIENTE", label: "4", color: "#4F6D7A" }
]
export const LOGIN_FORM = [
  {
    label: "Email",
    name: "email",
    placeholder: "Email*",
    type: "text",
    typeForm: "text",
    value: false,
    options: [],
    disable: false,
    className: "rs-100"
  },
  {
    label: "Password*",
    name: "password",
    placeholder: "Password*",
    type: "password",
    typeForm: "text",
    value: false,
    options: [],
    disable: false,
    className: "rs-100"
  }
]

export const DATA_DIA = [
  { hour: "1", Z01: 0, Z02: 0, Z04: 0, Z05: 0, Z11: 0, people: 0, company: 0 },
  { hour: "2", Z01: 0, Z02: 0, Z04: 0, Z05: 0, Z11: 0, people: 0, company: 0 },
  { hour: "3", Z01: 0, Z02: 0, Z04: 0, Z05: 0, Z11: 0, people: 0, company: 0 },
  { hour: "4", Z01: 0, Z02: 0, Z04: 0, Z05: 0, Z11: 0, people: 0, company: 0 },
  { hour: "5", Z01: 0, Z02: 0, Z04: 0, Z05: 0, Z11: 0, people: 0, company: 0 },
  { hour: "6", Z01: 0, Z02: 0, Z04: 0, Z05: 0, Z11: 0, people: 0, company: 0 },
  { hour: "7", Z01: 0, Z02: 0, Z04: 0, Z05: 0, Z11: 0, people: 0, company: 0 },
  { hour: "8", Z01: 0, Z02: 0, Z04: 0, Z05: 0, Z11: 0, people: 0, company: 0 },
  { hour: "9", Z01: 0, Z02: 0, Z04: 0, Z05: 0, Z11: 0, people: 0, company: 0 },
  { hour: "10", Z01: 0, Z02: 0, Z04: 0, Z05: 0, Z11: 0, people: 0, company: 0 },
  { hour: "11", Z01: 0, Z02: 0, Z04: 0, Z05: 0, Z11: 0, people: 0, company: 0 },
  { hour: "12", Z01: 0, Z02: 0, Z04: 0, Z05: 0, Z11: 0, people: 0, company: 0 },
  { hour: "13", Z01: 0, Z02: 0, Z04: 0, Z05: 0, Z11: 0, people: 0, company: 0 },
  { hour: "14", Z01: 0, Z02: 0, Z04: 0, Z05: 0, Z11: 0, people: 0, company: 0 },
  { hour: "15", Z01: 0, Z02: 0, Z04: 0, Z05: 0, Z11: 0, people: 0, company: 0 },
  { hour: "16", Z01: 0, Z02: 0, Z04: 0, Z05: 0, Z11: 0, people: 0, company: 0 },
  { hour: "17", Z01: 0, Z02: 0, Z04: 0, Z05: 0, Z11: 0, people: 0, company: 0 },
  { hour: "18", Z01: 0, Z02: 0, Z04: 0, Z05: 0, Z11: 0, people: 0, company: 0 },
  { hour: "19", Z01: 0, Z02: 0, Z04: 0, Z05: 0, Z11: 0, people: 0, company: 0 },
  { hour: "20", Z01: 0, Z02: 0, Z04: 0, Z05: 0, Z11: 0, people: 0, company: 0 },
  { hour: "21", Z01: 0, Z02: 0, Z04: 0, Z05: 0, Z11: 0, people: 0, company: 0 },
  { hour: "22", Z01: 0, Z02: 0, Z04: 0, Z05: 0, Z11: 0, people: 0, company: 0 },
  { hour: "23", Z01: 0, Z02: 0, Z04: 0, Z05: 0, Z11: 0, people: 0, company: 0 },
  { hour: "24", Z01: 0, Z02: 0, Z04: 0, Z05: 0, Z11: 0, people: 0, company: 0 },
];

export const BOTONES = [
  {
    label: "Iniciar Sesión",
    className: "admin-btn-login",
    action: false,
    style: "primary",
    id: 'js-btn-login',
    primary: true
  }
]


const getFilterFields = (handleAdd) => {

  const CONTACT_ESTADISTIC_FORM = [

    {
      label: "Fecha Inicial*",
      name: "fechaStart",
      placeholder: "",
      type: "date",
      typeForm: "date",
      value: false,
      options: [],
      disable: false,
      className: "rs-30"
    },
    {
      label: "Fecha Final*",
      name: "fechaEnd",
      placeholder: "",
      type: "date",
      typeForm: "date",
      value: false,
      options: [],
      disable: false,
      className: "rs-30"
    }, {
      name: "btnFiltrarCont",
      placeholder: "Filtrar",
      type: "buttonX",
      typeForm: "buttonX",
      primary: true,
      value: false,
      options: [],
      disabled: false,
      className: "rs-30",
      onClick: handleAdd,
    },
  ];
  return CONTACT_ESTADISTIC_FORM;
};

const getFilterFieldsVivi = (handleAdd) => {

  const CONTACT_ESTADISTIC_FORM = [

    {
      label: "Fecha Inicial*",
      name: "fechaStart",
      placeholder: "",
      type: "date",
      typeForm: "date",
      value: false,
      options: [],
      disable: false,
      className: "rs-30"
    },
    {
      label: "Fecha Final*",
      name: "fechaEnd",
      placeholder: "",
      type: "date",
      typeForm: "date",
      value: false,
      options: [],
      disable: false,
      className: "rs-30"
    },
    {
      name: "btnFiltrarViv",
      placeholder: "Filtrar",
      type: "buttonX",
      typeForm: "buttonX",
      primary: true,
      value: false,
      options: [],
      disabled: false,
      className: "rs-20",
      onClick: handleAdd,
    },
  ];
  return CONTACT_ESTADISTIC_FORM;
};
const getFilterFieldsDepor = (handleAdd) => {

  const CONTACT_ESTADISTIC_FORM = [

    {
      label: "Fecha Inicial*",
      name: "fechaStart",
      placeholder: "",
      type: "date",
      typeForm: "date",
      value: false,
      options: [],
      disable: false,
      className: "rs-30"
    },
    {
      label: "Fecha Final*",
      name: "fechaEnd",
      placeholder: "",
      type: "date",
      typeForm: "date",
      value: false,
      options: [],
      disable: false,
      className: "rs-30"
    },
    {
      name: "btnFiltrarDeport",
      placeholder: "Filtrar",
      type: "buttonX",
      typeForm: "buttonX",
      primary: true,
      value: false,
      options: [],
      disabled: false,
      className: "rs-20",
      onClick: handleAdd,
    },
  ];
  return CONTACT_ESTADISTIC_FORM;
};

export const contactEstadisticForm = (handleAdd) => {

  const CONTACT_ESTADISTIC_FORM = [
    {
      tooltip: "",
      label: "Seleccionar rango",
      name: "selectRange",
      placeholder: "Selecctionar rango",
      type: "select",
      typeForm: "select",
      value: false,
      options: [
        { label: "Hoy", value: "1" },
        { label: "Ayer", value: "2" },
        { label: "Últimos 7 dias", value: "3" },
        { label: "Últimos 15 dias", value: "4" },
        { label: "Esta semana", value: "5" },
        { label: "Este mes", value: "6" },
        { label: "Mes anterior", value: "7" },
        { label: "Personalizado", value: "8" },
      ],
      disable: false,
      className: "form-control rs-30"
    },
    {
      name: "personalizadoFiltro",
      type: "section",
      typeForm: "section",
      className: "rs-70",
      fields: getFilterFields(handleAdd),
      hidden: false,
    },
  ];
  return CONTACT_ESTADISTIC_FORM;
};

export const oportunidadForm = (handlesearch,handleupdate,handlesee) => {

  const CONTACT_ESTADISTIC_FORM = [
    {
      tooltip: "",
      label: "Consultar oportunidad",
      name: "searchOportunidad",
      placeholder: "Consultar Oportunidad",
      type: "number",
      typeForm: "text",
      value: false,
      options: [],
      disable: false,
      className: "form-control rs-30"
    },{
      name: "btnFiltrarViv",
      placeholder: "Consultar",
      type: "buttonX",
      typeForm: "buttonX",
      primary: true,
      value: false,
      options: [],
      disabled: false,
      className: "rs-20",
      onClick: handlesearch,
    } 
    
    
  ];
  return CONTACT_ESTADISTIC_FORM;
};

export const viviendaEstadisticForm = (handleAdd) => {

  const CONTACT_ESTADISTIC_FORM = [
    {
      tooltip: "",
      label: "Seleccionar rango",
      name: "selectRange",
      placeholder: "Selecctionar rango",
      type: "select",
      typeForm: "select",
      value: false,
      options: [
        { label: "Hoy", value: "1" },
        { label: "Ayer", value: "2" },
        { label: "Últimos 7 dias", value: "3" },
        { label: "Últimos 15 dias", value: "4" },
        { label: "Esta semana", value: "5" },
        { label: "Este mes", value: "6" },
        { label: "Mes anterior", value: "7" },
        { label: "Personalizado", value: "8" },
      ],
      disable: false,
      className: "form-control rs-30"
    },
    {
      name: "personalizadoVivienda",
      type: "section",
      typeForm: "section",
      className: "rs-70",
      fields: getFilterFieldsVivi(handleAdd),
      hidden: false,
    }
  ];
  return CONTACT_ESTADISTIC_FORM;
};

export const deporteEstadisticForm = (handleAdd) => {

  const CONTACT_ESTADISTIC_FORM = [
    {
      tooltip: "",
      label: "Seleccionar rango",
      name: "selectRange",
      placeholder: "Selecctionar rango",
      type: "select",
      typeForm: "select",
      value: false,
      options: [
        { label: "Hoy", value: "1" },
        { label: "Ayer", value: "2" },
        { label: "Últimos 7 dias", value: "3" },
        { label: "Últimos 15 dias", value: "4" },
        { label: "Esta semana", value: "5" },
        { label: "Este mes", value: "6" },
        { label: "Mes anterior", value: "7" },
        { label: "Personalizado", value: "8" },
      ],
      disable: false,
      className: "form-control rs-30"
    },
    {
      name: "personalizadoDeportes",
      type: "section",
      typeForm: "section",
      className: "rs-70",
      fields: getFilterFieldsDepor(handleAdd),
      hidden: false,
    }
  ];
  return CONTACT_ESTADISTIC_FORM;
};

export const getLoginButton = (handlerSafe) => {
  return [
    {
      label: "Filtrar",
      className: "auth-btn-send rs-20",
      action: false,
      style: "primary",
      primary: true,
      onClick: handlerSafe
    }
  ]
}

export const getButtonLogin = (handlerSafe) => {
  return [
    {
      label: "Iniciar Sesión",
      className: "auth-btn-send rs-20",
      action: false,
      style: "primary",
      primary: true,
      onClick: handlerSafe
    }
  ]
}
