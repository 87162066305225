import { getFormValues, } from 'redux-form' // ES6;
import { LOGIN_FORM_NAME } from "./constants";

export const getOportunidadForm = (state) => getFormValues("buscandoOportunidadForm")(state);
export const getContactEstadicticForm = (state) => getFormValues("contactEstadisticForm")(state);
export const getViviendaEstadicticForm = (state) => getFormValues("viviendaEstadisticForm")(state);
export const getDeportesEstadicticForm = (state) => getFormValues("deportEstadisticForm")(state);
export const getLoginFormValues = (state) => getFormValues(LOGIN_FORM_NAME)(state);
export const getCurrentTab = (state) => state.localReducer.currentTab;
export const getMessage = (state) => state.localReducer.message;
export const getEvent= (state) => state.localReducer.cargarEvent;
export const getSessionUser= (state) => state.localReducer.sessionUser;
export const getCargandoModal= (state) => state.localReducer.cargandoModal;
export const getItemsPdf= (state) => state.localReducer.itemsPdf;
export const getVisualizarPdf= (state) => state.localReducer.visualizarPdf;
export const getBase64Pdf= (state) => state.localReducer.base64Pdf;
export const getError= (state) => state.localReducer.error
export const getOcultaBoton= (state) => state.localReducer.ocultaBoton;
export const getCatalogos= (state) => state.localReducer.catalogo;
export const getShowSnackBar= (state) => state.localReducer.showSnackBar;
export const getConstruirBuscarOportunidad = (state) => state.localReducer.construirBuscarOportunidad;
export const getContextAuth0 = (state) => state.localReducer.contextAuth0;


export const getDataGraph1 = (state) => state.localReducer.dataGraph1;
export const getDataGraph2 = (state) => state.localReducer.dataGraph2;
export const getDataGraph3 = (state) => state.localReducer.dataGraph3;
export const getFormValid = (state) => state.localReducer.formValid;
export const getHiddenDisable = (state) => state.localReducer.hiddenDisable;
export const getDynamicOptions = (state) => state.localReducer.dynamicOptions;
